import { Box, Typography } from "@mui/material";
import styles from "../components.module.css";
import { colors } from "../../themes/colors";
import { Heart } from "../../assests/exportImages";
import { typographyOptions } from "../../constants";

interface ILikes {
  positionAbs?: string;
  likesCount: number;
}

export const Likes = (props: ILikes) => {
  const { positionAbs = "yes", likesCount } = props;
  return (
    <>
      {likesCount > 0 ? (
        <Box
          className={styles.likesIconMain}
          sx={{
            backgroundColor: colors.primaryWhite,
            position: positionAbs === "no" ? "relative" : "absolute",
          }}
        >
          <img src={Heart} alt="heart icon" />

          <Typography variant={typographyOptions.H1} color={colors.primaryGrey}>
            {props?.likesCount}
          </Typography>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
