import { Avatar, Box, Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  formatDate,
  getFullName,
  getImagefromBase64,
  getInitials,
  getStoredImages,
} from "../../helper";

import {
  BackIcon,
  calenderIcon,
  daysIcon,
  PopularTripsDummy,
  userIcon,
} from "../../assests/exportImages";
import DynamicTabs from "../../components/atom/DynamicTabs";
import { Likes } from "../../components/atom/Likes";
import { PassIcon } from "../../components/atom/PassIcon";
import AboutMeCard from "../../components/molecule/AboutMeCard";
import FollowersUI from "../../components/molecule/FollwersUI";
import LocationUI from "../../components/molecule/LocationUI";
import { SkeletonCard } from "../../components/skeltonLoader/cardSkelton";
import Header from "../../components/template/Header";
import { typographyOptions } from "../../constants";
import {
  getFeaturedUserDetails,
  getFeaturedUserPublishedTrips,
  getItineraryDetails,
} from "../../redux/actions/screenActions";
import { colors } from "../../themes/colors";
import styles from "./index.module.css";
import { logPageView } from "../../utils/analytics";
import en from "../../locale/en.json";

// import { logPageView } from "../../analytics";

const TravellerDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const {
    featuredUserDetail = [],
    featuredUserDetailLoader,
    featuredUserTripsList = [],
    loadingAllImages,
  } = useSelector((state: any) => state.screenReducer);

  const {
    name,
    username,
    about,
    userImageAsBase64,
    followerCount,
    tripsAssociatedCount,
    location,
  } = featuredUserDetail;

  const [tabValue, setTabValue] = useState<string>("2");

  const handleChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (params.id) {
      dispatch(getFeaturedUserDetails(params.id));
      dispatch(getFeaturedUserPublishedTrips(params.id));
    }

    // // google analytics
    // let obj = {
    //   name: en.anayticsPageName.TRAVEL_DETAILS,
    //   path: location.path,
    // };
    // logPageView(obj);
  }, []);

  const handleCardClick = (card) => {
    const image = getStoredImages(card.reference);
    navigate(`/itinerary-detail/${card.id}`, { state: { card, image } });
    dispatch(getItineraryDetails(card.id));
  };

  const avatar = getInitials(getFullName(name));

  const tabs = [
    {
      label: en.publishedTrips,
      value: "2",
      content: (
        <Box className="traveller-detail-cards-list-detail-page">
          {featuredUserTripsList?.trips?.map((data: any, index: any) => (
            <Card
              key={index}
              data={data}
              height="130px"
              onClick={() => handleCardClick(data)}
            />
          ))}
        </Box>
      ),
    },
    {
      label: en.aboutMe,
      value: "1",
      content: about ? (
        <AboutMeCard about={about} />
      ) : (
        <Typography
          variant={typographyOptions.H1}
          color={colors.black}
          textAlign="center"
        >
          This traveller is still packing their bio. Check back soon!
        </Typography>
      ),
    },
  ];

  return (
    <Box>
      {" "}
      <Header />
      <Box className={styles.travellerDetailDesktop}>
        {/* for desktop view */}
        <Box className={styles.travellerDetailImg}>
          <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />

          <Typography
            variant={typographyOptions.H2}
            color={colors.transparentWhite}
          >
            Back
          </Typography>
        </Box>

        {/* main ui started */}
        <Box style={{ display: "flex", padding: "40px 4%", gap: "20px" }}>
          <Box>
            <Box>
              <Box className={styles.travllerDetailLeftCard}>
                <Box
                  style={{
                    boxShadow: "0px 3px 28px 3px #0000000A",
                    borderRadius: "10px",
                  }}
                >
                  <Box
                    style={{ border: "4px solid white", borderRadius: "30px" }}
                  >
                    {userImageAsBase64 === "" ? (
                      <Avatar className={styles.avatar}>{avatar}</Avatar>
                    ) : featuredUserDetailLoader ? (
                      <Skeleton
                        variant="rectangular"
                        width={180}
                        height={180}
                      />
                    ) : (
                      <img
                        src={getImagefromBase64(userImageAsBase64)}
                        style={{
                          borderRadius: "30px",
                          objectFit: "cover",
                          width: "180px",
                          height: "180px",
                        }}
                      />
                    )}
                  </Box>
                </Box>

                <Box style={{ textAlign: "center" }}>
                  {featuredUserDetailLoader ? (
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width="100px"
                      height="30px"
                    />
                  ) : (
                    <>
                      <Typography
                        variant={typographyOptions.H2}
                        color={colors.black}
                        fontSize="14px"
                      >
                        {name}
                      </Typography>
                      <Typography
                        variant={typographyOptions.H2}
                        color={colors.secondaryGrey}
                        fontSize="14px"
                      >
                        @{username}
                      </Typography>
                    </>
                  )}
                </Box>
                {location ? (
                  <LocationUI
                    location={location}
                    loading={featuredUserDetailLoader}
                  />
                ) : (
                  <></>
                )}

                <FollowersUI
                  trips={tripsAssociatedCount}
                  followers={followerCount}
                  loading={featuredUserDetailLoader}
                />
              </Box>
            </Box>
          </Box>
          <Stack gap="40px" width="100%">
            {about ? <AboutMeCard about={about} addSeperator={true} /> : <></>}
            <Typography
              variant={typographyOptions.H2}
              color={colors.black}
              fontSize="18px"
              marginLeft="5px"
            >
              Trips
            </Typography>
            <Box className={styles.travellerDetailsList}>
              {featuredUserDetailLoader ? (
                Array.from({ length: 5 }, (_, i) => <SkeletonCard key={i} />)
              ) : (
                <>
                  {featuredUserTripsList?.trips?.map(
                    (data: any, index: number) => (
                      <Card
                        key={index}
                        data={data}
                        image={getStoredImages(data?.reference)}
                        width="244px"
                        onClick={() => handleCardClick(data)}
                        loadingAllImages={loadingAllImages}
                      />
                    )
                  )}
                </>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
      <Box className={styles.travllerDetailMobileScreen}>
        <Box className={styles.travllerDetailMobileHeader}>
          <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />

          {username ? (
            <Typography
              variant={typographyOptions.H2}
              color={colors.black}
              marginTop="10px"
            >
              {username}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
        <Box className={styles.travllerDetailsAllDetailsMobile}>
          <Stack gap="25px" width="100%" marginBottom={"20px"}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box
                style={{
                  boxShadow: "0px 3px 28px 3px #0000000A",
                  borderRadius: "10px",
                }}
              >
                <Box
                  style={{ border: "4px solid white", borderRadius: "30px" }}
                >
                  {userImageAsBase64 === "" ? (
                    <Avatar className={styles.avatarMobile}>{avatar}</Avatar>
                  ) : featuredUserDetailLoader ? (
                    <Skeleton variant="rectangular" width={80} height={80} />
                  ) : (
                    <img
                      src={getImagefromBase64(userImageAsBase64)}
                      style={{
                        borderRadius: "24px",
                        width: "80px",
                        height: "80px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </Box>
              </Box>
              <FollowersUI
                trips={tripsAssociatedCount}
                followers={followerCount}
                loading={featuredUserDetailLoader}
              />
            </Box>
            <Stack gap="10px">
              <Box>
                {featuredUserDetailLoader ? (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="100px"
                    height="30px"
                  />
                ) : (
                  <>
                    {" "}
                    <Typography
                      variant={typographyOptions.H2}
                      color={colors.black}
                      fontSize="14px"
                    >
                      {name}
                    </Typography>
                    {username ? (
                      <Typography
                        variant={typographyOptions.H2}
                        color={colors.secondaryGrey}
                        fontSize="14px"
                      >
                        @{username}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Box>
              {location ? (
                <Box sx={{ display: "flex", alignSelf: "baseline" }}>
                  <LocationUI
                    location={location}
                    loading={featuredUserDetailLoader}
                  />
                </Box>
              ) : (
                <></>
              )}
            </Stack>
          </Stack>

          {/* Dynamic Tabs for mobile view */}
          <DynamicTabs tabs={tabs} value={tabValue} onChange={handleChange} />
        </Box>
      </Box>
    </Box>
  );
};

export default TravellerDetail;

const Card = ({ data, height, onClick, width, loadingAllImages }: any) => {
  const image: any = getStoredImages(data?.reference);
  const startDateDay: any = formatDate(data?.startDate, "string");
  const getEndDate = () => {
    const date: any = formatDate(data?.endDate, "string");
    return `${date?.day} ${date?.month} ${date?.year}`;
  };
  return (
    <Box className={styles.travllerDetailTripCard} onClick={onClick}>
      {loadingAllImages ? (
        <Skeleton
          variant="rectangular"
          width={250}
          height={200}
          sx={{ borderRadius: "12px" }}
        />
      ) : (
        <Box
          className={styles.backgroundImg}
          style={{
            backgroundImage: `url(${image?.config?.url || PopularTripsDummy})`,
            height: height ? height : "202px",
            width: width ? width : "",
          }}
        >
          <Likes likesCount={data?.likes} />
        </Box>
      )}
      <Box style={{ padding: "0px 8px 8px 10px" }}>
        <Typography variant={typographyOptions.H2} color={colors.black}>
          {data.name}
        </Typography>
        <Box className={styles.travllerDetailTripCardDescription}>
          <Box className={styles.cardDescriptionItems}>
            <img src={calenderIcon} alt="Calendar" />
            <Typography variant={typographyOptions.SUB_HEADING}>
              {`${
                data?.startDate
                  ? `${startDateDay?.day} - ${getEndDate()}`
                  : `${data?.days || 0} days`
              }`}
            </Typography>
          </Box>
          <Box className={styles.cardDescriptionItems}>
            <img src={daysIcon} alt="Days" />
            <Typography variant={typographyOptions.SUB_HEADING}>
              {data.places?.length} Places
            </Typography>
          </Box>
        </Box>
        {data?.associatedUsers?.[0]?.name && (
          <Box className="md:flex hidden gap-[4px]" sx={{ marginTop: "8px" }}>
            <img src={userIcon} alt="User" />
            <Typography variant={typographyOptions.SUB_HEADING}>
              {" "}
              {data?.associatedUsers?.[0]?.name}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
