import { Box, Container, Stack } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CardsWithScroll from "../../components/template/CardsWithScroll";
import Footer from "../../components/template/Footer";
import Header from "../../components/template/Header";
import { getStoredImages } from "../../helper";
import en from "../../locale/en.json";
import { screenActionsCall } from "../../redux/actions";
import { logPageView } from "../../utils/analytics";
import FeaturedTravellers from "../components/FeaturedTravellers";
import { SearchItenairy } from "../components/SearchItenairy";
import { SearchPageContent } from "../components/SearchPagecontent";
import VacationList from "../components/VacationList";
import Helmet from "react-helmet";

const TravelGuides = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    name: "",
    displayName: "",
  });

  const searchPageRef = useRef(null); // Create a ref

  const {
    publishedTrips,
    loadingAllImages,
    publishedTripsLoader,
    longTrips,
    longTripsLoader,
    weekendGateWays,
    weekendGatewaysLoader,
  } = useSelector((state: any) => state.screenReducer);

  useEffect(() => {
    dispatch(screenActionsCall.getFeaturedUsers());
    dispatch(screenActionsCall.getPublishedTrip());
    dispatch(screenActionsCall.getLongTrips());
    dispatch(screenActionsCall.getWeekendGateways());

    // google analytics
    let obj = {
      name: en.anayticsPageName.TRAVEL_GUIDE,
      path: location.pathname,
    };
    logPageView(obj);
  }, []);

  useEffect(() => {
    if (searchInput.length > 2) {
      const payload = {
        queryParams: searchInput,
      };
      dispatch(screenActionsCall.getSearchItinerary(payload));

      // Scroll to the SearchPageContent component
      if (searchPageRef.current) {
        searchPageRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [searchInput]);

  useEffect(() => {
    const payload = {
      sort: sortBy.toLowerCase(),
      tripType: selectedOption.name,
    };
    dispatch(screenActionsCall.getSortedItinerary(payload));
  }, [sortBy, selectedOption]);

  const handleCardClick = (card: any) => {
    const image = getStoredImages(card.reference);
    navigate(`/itinerary-detail/${card.id}`, { state: { card, image } });
  };

  const handleChangeText = (value: string) => {
    setSearchInput(value);
    setSelectedOption({
      name: "",
      displayName: "",
    });
    setSortBy("");
  };

  return (
    <Stack gap="90px">
      <Helmet>
        <title>Escapekar: Your Ultimate Travel Guide</title>
        <meta name="title" content="Escapekar: Your Ultimate Travel Guide" />
        <meta
          name="description"
          content="Plan your perfect trip with Escapekar's ultimate travel guide. Discover featured travels, hidden gems, and popular destinations. Get personalized recommendations and explore any place you desire with our advanced search functionality."
        />
        <meta
          name="keywords"
          content="travel guide, featured travels, hidden gems, popular destinations, travel itinerary, trip planner, personalized recommendations, search destinations, travel planning"
        />

        <meta name="url" content="https://escapekar.com/" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Escapekar: Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover featured travels, hidden gems, and popular destinations with Escapekar's travel guide. Get personalized trip planning recommendations and explore any location with our comprehensive search."
        />
        <meta property="og:site_name" content="Escapekar" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="IN" />

        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TravelGuide",
            name: "Escapekar Travel Guide",
            description:
              "Your ultimate resource for travel planning. Discover featured travels, hidden gems, and popular destinations. Get personalized recommendations and search for any location.",
            url: "https://escapekar.com/",
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],

            hasPart: [
              {
                "@type": "TravelDestination",
                name: "Featured Travels",
                description:
                  "Explore our featured travel destinations and experiences.",
              },
              {
                "@type": "TravelDestination",
                name: "Hidden Gems",
                description:
                  "Discover hidden gems and lesser-known destinations.",
              },
              {
                "@type": "TravelDestination",
                name: "Popular Destinations",
                description:
                  "Browse popular destinations that are favored by travelers.",
              },
            ],
            contactPoint: {
              "@type": "ContactPoint",
              areaServed: "IN",
              availableLanguage: "en",
            },
          })}
        </script>
      </Helmet>
      <Header />

      <SearchItenairy
        handleChangeText={handleChangeText}
        searchInput={searchInput}
      />

      {searchInput.length >= 2 ? (
        <Box ref={searchPageRef}>
          {/* Attach ref here */}
          <SearchPageContent
            searchInput={searchInput}
            handleCardClick={handleCardClick}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            setSortBy={setSortBy}
            sortBy={sortBy}
          />
        </Box>
      ) : (
        <Stack gap="90px">
          {/* popular trips */}
          <CardsWithScroll
            heading={en.popularTrips}
            description={en.diveIntoItinerariesCustomersText}
            cards={publishedTrips?.trips}
            showbtn={false}
            loadingAllImages={loadingAllImages}
            loading={publishedTripsLoader}
            handleCardClick={handleCardClick}
          />

          <CardsWithScroll
            heading={en.weekendGateways}
            cards={weekendGateWays}
            showbtn={false}
            loadingAllImages={loadingAllImages}
            loading={weekendGatewaysLoader}
            handleCardClick={handleCardClick}
          />

          <CardsWithScroll
            heading={en.longTrips}
            cards={longTrips}
            showbtn={false}
            loadingAllImages={loadingAllImages}
            loading={longTripsLoader}
            handleCardClick={handleCardClick}
          />
          <Container>
            <VacationList />
          </Container>
          <FeaturedTravellers />
        </Stack>
      )}
      <Footer />
    </Stack>
  );
};

export default TravelGuides;
