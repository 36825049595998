import {
  HotAirBalloon,
  Montreal,
  Salar,
  Sunken,
  WarmUpWarm,
} from "../../assests/exportImages";

export const HiddenSpots = () => {
  return (
    <div className="Fold3 relative flex flex-col h-full lg:min-h-[800px] w-full  items-center bg-[#105847] pb-16 md:pb-20 lg:pb-5 ">
      <img
        src={HotAirBalloon}
        alt="Hot Air Balloon"
        className="hidden lg:flex absolute -top-[12%] left-[2%]"
      />
      <h2 className=" text-[24px] lg:text-[46px] font-bold font-Mulish mt-16 lg:mt-20 text-[#ffffff]">
        Look for hidden spots
      </h2>
      <p className="text-base md:text-2xl lg:text-base text-center lg:text-left font-normal font-Mulish mt-2 lg:mt-0 mb-28 lg:mb-28 text-[#ffffff80]">
        All the hidden spots will be on your finger tips
      </p>
      <div className="Cards flex flex-row gap-10 md:gap-16 lg:gap-28 flex-wrap lg:flex-initial justify-center">
        <div className="Card flex flex-col h-[145px] w-[110px] md:h-[290px] md:w-[220px] items-center justify-end rounded-[20px] -mt-6 ml-5 lg:ml-0 lg:mt-24">
          <img alt="Scene" src={Salar} className=" mb-5" />
          <p className="text-sm md:text-base font-bold font-Mulish text-[#ffffff] whitespace-nowrap">
            Salar de Uyuni
          </p>
          <p className="text-xs md:text-base font-semibold font-Mulish text-[#ffffff80] whitespace-nowrap">
            Bolivia
          </p>
        </div>
        <div className="Card flex flex-col h-[145px] w-[110px] md:h-[290px] md:w-[220px] items-center justify-end rounded-[20px] mt-24 lg:mt-0 mr-5 lg:mr-0">
          <img alt="Scene" src={Montreal} className=" mb-5" />
          <p className="text-sm md:text-base font-bold font-Mulish text-[#ffffff] whitespace-nowrap">
            Montreal Garden
          </p>
          <p className="text-xs md:text-base font-semibold font-Mulish text-[#ffffff80] whitespace-nowrap">
            Canada
          </p>
        </div>
        <div className="Card flex flex-col h-[145px] w-[110px] md:h-[290px] md:w-[220px] items-center justify-end rounded-[20px] -mt-6 ml-5 lg:ml-0 lg:mt-24">
          <img alt="Scene" src={WarmUpWarm} className=" mb-5" />
          <p className="text-sm md:text-base font-bold font-Mulish text-[#ffffff] whitespace-nowrap">
            Rainbow Mountains
          </p>
          <p className="text-xs md:text-base font-semibold font-Mulish text-[#ffffff80] whitespace-nowrap">
            Gansu, China
          </p>
        </div>
        <div className="Card flex flex-col h-[145px] w-[110px] md:h-[290px] md:w-[220px] items-center justify-end rounded-[20px] mt-24 lg:mt-0 mr-5 lg:mr-0">
          <img alt="Scene" src={Sunken} className=" mb-5" />
          <p className="text-sm md:text-base font-bold font-Mulish text-[#ffffff] whitespace-nowrap">
            The Great Blue Hole
          </p>
          <p className="text-xs md:text-base font-semibold font-Mulish text-[#ffffff80] whitespace-nowrap">
            Belize, America
          </p>
        </div>
      </div>
    </div>
  );
};
