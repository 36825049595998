import axios from "axios";
import { BASE_URL } from "./apiurls";
const token = localStorage.getItem("Login_Token") || "";

const tokenWithoutQuotes = token.replace(/"/g, "");

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${tokenWithoutQuotes}`,
  },
});
export default axiosInstance;
