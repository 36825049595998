import ReactGA from 'react-ga4';

// Define the types for props in logPageView
interface LogPageViewProps {
  path?: string;
  name?: string;
}

// Initialize Google Analytics
export const initGA = (measurementId: string): void => {
  ReactGA.initialize(measurementId);
};

// Log page views
export const logPageView = (props: LogPageViewProps = {}): void => {
  const { path, name } = props;
  ReactGA.send({
    hitType: 'pageview',
    page: path || window.location.pathname + window.location.search,
    title: name || document.title, // Use document.title for the page title
  });
};

// Log events
export const logEvent = (eventCategory: string, eventAction: string): void => {
  ReactGA.event({ category: eventCategory, action: eventAction });
};