import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MobileImage, stars, yellowStar } from "../../assests/exportImages";
import { typographyOptions } from "../../constants";
import { screenRoutes } from "../../constants/routeConstants";
import en from "../../locale/en.json";
import { colors } from "../../themes/colors";
import styles from "./components.module.css";

const DownloadAppCard = () => {
  const navigate = useNavigate();
  return (
    // <Box className="bothSidesPadding">
    <Box className={styles?.downloadAppCard}>
      <Box className={styles?.downloadAppLeft}>
        <Stack alignItems="baseline">
          <Typography
            variant={typographyOptions.CARDS_HEADING}
            color={colors.primaryWhite}
          >
            {en.craftYourDreamTripInSecondswithAI}
          </Typography>
          <Typography
            variant={typographyOptions.H2}
            color={colors.secondaryGrey}
          >
            Answer a few questions and let AI plan your trip!
          </Typography>
        </Stack>

        <Button
          variant="contained"
          sx={{
            backgroundColor: colors.primaryWhite,
            color: colors.transparentWhite,
            textTransform: "none",
            padding: "13px 30px 13px 30px",
            borderRadius: "10px",
            width: "200px",
            "&:hover": {
              backgroundColor: "#faf9f6",
              borderColor: colors.ThemeColor,
              borderWidth: "1px",
              "& .MuiTypography-root": {
                color: "white", // Change text color on hover
              },
            },
          }}
          onClick={() => navigate(screenRoutes.GET_APP)}
        >
          <Typography variant="heading">{en.downloadApp}</Typography>
        </Button>
      </Box>
      <Box
        className={styles.imageContainer}
        style={{
          backgroundImage: `url(${stars}),url(${yellowStar})`,
        }}
      >
        <Box>
          <img src={MobileImage} alt="Escapekar : Mobile image" />
        </Box>
      </Box>
    </Box>
    // </Box>
  );
};

export default DownloadAppCard;
