import { Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/atom/CustomButton";
import Input from "../../../components/atom/Input";
import { typographyOptions } from "../../../constants";
import { authRoutes } from "../../../constants/routeConstants";
import en from "../../../locale/en.json";
import { initiateResetPassword } from "../../../redux/actions/authActions";
import { RootState } from "../../../redux/reducers";
import { colors } from "../../../themes/colors";
import { checkWhiteSpace, scrollToTop, validateEmail } from "../../../utils/helpers";
import { useEffect } from "react";

const ForgotPassword = ({
  errorFields,
  handleChange,
  fields,
  setErrorFields,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state: RootState) => state.authReducer);

  const handleSendEmailClick = () => {
    const emailCheck = validateEmail(fields.email);

    if (!emailCheck.status) {
      setErrorFields((prevState: any) => ({
        ...prevState,
        email: emailCheck.error,
      }));
      return;
    }

    dispatch(
      initiateResetPassword({
        email: fields.email,
        onSuccess: () => {
          navigate(authRoutes.EMAIL_VERIFICATION);
        },
        onError: (error: any) => {
          setErrorFields((prevState: any) => ({
            ...prevState,
            email: error.message,
          }));
        },
      })
    );
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Stack gap="40px">
      <Typography variant={typographyOptions.AUTH_HEADING}>
        {en.forgotPassword}
      </Typography>

      <Stack gap="35px">
        <Typography variant={typographyOptions.H1} color={colors.secondaryGrey}>
          {en.forgotPasswordTextMessage}
        </Typography>

        {/* sign in form */}
        <Stack gap="20px">
          <Stack gap="10px">
            <Typography
              variant={typographyOptions.H1}
              color={colors.primaryGrey}
              paddingLeft="10px"
            >
              {en.enterEmail}
            </Typography>
            <Input
              value={fields.email}
              onChange={(e: any) => {
                checkWhiteSpace(e.target.value) &&
                  handleChange("email", e.target.value);
              }}
              errormessage={errorFields?.email}
            />
          </Stack>
        </Stack>
        {/* sign in button */}
        <CustomButton
          variant="contained"
          title={en.sendEmail}
          fontSize="16px"
          loading={loading}
          customStyle={{
            backgroundColor: colors.black,
            maxWidth: "420px",
            border: "2px solid #333333",
            borderRadius: "36px",
            "&:hover": {
              backgroundColor: colors.black,
              borderColor: colors.black,
              borderWidth: "0px",
              "& .MuiTypography-root": {
                color: colors.primaryWhite,
              },
            },
          }}
          customTypography={{
            color: colors.primaryWhite,
          }}
          onClick={handleSendEmailClick}
        />
      </Stack>
    </Stack>
  );
};

export default ForgotPassword;
