import { Avatar, Divider, Popover, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Hidden from "@mui/material/Hidden";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
// import { logEvent } from "../analytics";
import { useDispatch, useSelector } from "react-redux";
import {
  btnMobileIcon,
  Logo,
  SearchIcon,
  SideBarIcon,
} from "../../assests/exportImages";
import { typographyOptions } from "../../constants";
import { authRoutes, screenRoutes } from "../../constants/routeConstants";
import { quickLinksArr } from "../../helper";
import en from "../../locale/en.json";
import { RootState } from "../../redux/reducers";
import { colors } from "../../themes/colors";
import {
  getFullName,
  getImagefromBase64,
  getInitials,
  isLoggedIn,
} from "../../utils/helpers";
import { CustomButton } from "../atom/CustomButton";
import { PassIcon } from "../atom/PassIcon";
import styles from "../components.module.css";
import { toast } from "react-toastify";

interface IHeader {
  boxShadow?: string;
}

const Header = (props: IHeader) => {
  const location = useLocation();
  const { boxShadow } = props;
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const { userDetails } = useSelector(
    (state: RootState) => state.screenReducer
  );

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const loggedIn = isLoggedIn();
  const avatar = getInitials(
    getFullName(userDetails?.name ? userDetails?.name : "Escapekar")
  );

  return (
    <Box
      className={styles.headerPadding}
      style={{
        boxShadow: boxShadow ? boxShadow : "0px 3px 28px 3px #0000000a",
        // marginBottom: "70px",
      }}
    >
      <AppBar position="static">
        <Toolbar disableGutters>
          {/* For Mobile */}
          <Hidden mdUp>
            <Box
              className={styles.headerMain}
              sx={{
                backgroundColor: colors.primaryWhite,
                boxShadow: boxShadow ? boxShadow : "0px 3px 28px 3px #0000000a",
              }}
            >
              <Box className={styles.headerBox1}>
                <Stack alignItems="baseline">
                  <Typography variant="heading" color={colors.black}>
                    Download our app today
                  </Typography>
                  <Typography variant="subHeading" color={colors.primaryGrey}>
                    Trip planning made easy
                  </Typography>
                </Stack>
                <Link
                  to="/get-app"
                  className="text-[16px] font-normal font-Mulish text-[#999999] hover:text-[#333333]"
                >
                  <CustomButton
                    title={en?.getApp}
                    startIcon={btnMobileIcon}
                    border={"3px solid #CCF4EC"}
                    width="107px"
                    // onClick={() => logEvent('Button', 'Click')}
                  />
                </Link>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <PassIcon icon={SideBarIcon} onClick={handleOpenNavMenu} />

                <Link
                  to={screenRoutes.HOME}
                  style={{
                    textAlign: "center",
                    width: "35%",
                    marginRight: "30px",
                  }}
                >
                  <Logo />
                </Link>
                <Box
                  sx={{
                    display: "flex",
                    gap: "25px",
                    flexGrow: 0,
                    alignItems: "center",
                  }}
                >
                  <Link to={screenRoutes.SEARCH}>
                    <img src={SearchIcon} alt="" />
                  </Link>
                  {!loggedIn ? (
                    <Link to={authRoutes.LOGIN}>
                      <Typography
                        variant={typographyOptions.SUB_HEADING}
                        color={colors.black}
                        sx={{
                          display: { xs: "none", md: "block" },
                        }}
                      >
                        {"Log in"}
                      </Typography>
                    </Link>
                  ) : (
                    <></>
                  )}

                  {loggedIn ? (
                    <>
                      <Box
                        onClick={(e: any) => handleClick(e)}
                        sx={{ cursor: "pointer" }}
                      >
                        {userDetails?.userImageAsBase64 === "" ? (
                          <Avatar
                            sx={{
                              backgroundColor: " #20b08f",
                              color: "white",
                              width: "45px",
                              height: "45px",
                              borderRadius: "50%",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            {avatar}
                          </Avatar>
                        ) : (
                          <img
                            src={getImagefromBase64(
                              userDetails?.userImageAsBase64
                            )}
                            style={{
                              borderRadius: "30px",
                              objectFit: "cover",
                              width: "50px",
                              height: "50px",
                            }}
                          />
                        )}
                      </Box>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        sx={{
                          borderRadius: "20px",
                        }}
                      >
                        <Stack
                          sx={{
                            padding: "20px 10px",
                            gap: "10px",
                            width: "180px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "10px",
                              alignItems: "center",
                              padding: "0px 10px",
                            }}
                          >
                            {userDetails?.userImageAsBase64 === "" ? (
                              <Avatar
                                sx={{
                                  backgroundColor: " #20b08f",
                                  color: "white",
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: "50%",
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                }}
                              >
                                {avatar}
                              </Avatar>
                            ) : (
                              <img
                                src={getImagefromBase64(
                                  userDetails?.userImageAsBase64
                                )}
                                style={{
                                  borderRadius: "30px",
                                  objectFit: "cover",
                                  width: "50px",
                                  height: "50px",
                                }}
                              />
                            )}

                            {!userDetails?.username && !userDetails?.name ? (
                              <Typography
                                variant={typographyOptions.H1}
                                color={colors.black}
                                sx={{ textDecoration: "underline" }}
                              >
                                +Add details
                              </Typography>
                            ) : !userDetails?.username && userDetails?.name ? (
                              <Stack>
                                {" "}
                                <Typography
                                  variant={typographyOptions.H1}
                                  color={colors.black}
                                >
                                  +Add details
                                </Typography>
                                <Typography
                                  variant={typographyOptions.SUB_HEADING}
                                  color={colors.quatenaryGrey}
                                >
                                  {userDetails?.username}
                                </Typography>
                              </Stack>
                            ) : userDetails?.username && !userDetails?.name ? (
                              <Stack>
                                <Typography
                                  variant={typographyOptions.H1}
                                  color={colors.black}
                                >
                                  +Add username
                                </Typography>
                                <Typography
                                  variant={typographyOptions.SUB_HEADING}
                                  color={colors.quatenaryGrey}
                                >
                                  {userDetails?.username}
                                </Typography>
                              </Stack>
                            ) : (
                              <Stack>
                                <Typography
                                  variant={typographyOptions.H1}
                                  color={colors.black}
                                >
                                  {userDetails?.name}
                                </Typography>
                                <Typography
                                  variant={typographyOptions.SUB_HEADING}
                                  color={colors.tertiaryGrey}
                                >
                                  {userDetails?.username}
                                </Typography>
                              </Stack>
                            )}
                          </Box>

                          <Divider />
                          <Typography
                            variant={typographyOptions.H1}
                            sx={{ padding: "0px 15px", cursor: "pointer" }}
                            onClick={() => {
                              handleClose();
                              localStorage.removeItem("Login_Token");
                              toast.success("User Logged out successfully");
                            }}
                          >
                            Log out
                          </Typography>
                        </Stack>
                      </Popover>
                    </>
                  ) : (
                    <Link to={authRoutes.REGISTER}>
                      <CustomButton
                        title={en.signUp}
                        border={"3px solid #CCF4EC"}
                        customStyle={{ borderRadius: "35px" }}
                      />
                    </Link>
                  )}
                </Box>
              </Box>
            </Box>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {quickLinksArr.map((quickLink, index) => (
                <Link
                  key={index}
                  to={quickLink?.link}
                  className="text-[#999999] hover:text-[#333333] mb-1 lg:mb-0"
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" variant="subHeading">
                      {quickLink.text}
                    </Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Hidden>

          {/* For Desktop */}
          <Hidden mdDown>
            <Typography
              noWrap
              component="a"
              href={screenRoutes.HOME}
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                textDecoration: "none",
                width: "200px",
              }}
            >
              <Link to={screenRoutes.HOME}>
                <Logo />
              </Link>
            </Typography>

            {/* Vertical Separator Line */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                mx: 2,
                height: "40px",
                borderLeft: "1px solid #ddd",
              }}
            />

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex", gap: "32px" },
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex", gap: "32px" },
                }}
              >
                {quickLinksArr.map((quickLink, index) => (
                  <Link
                    key={index}
                    to={quickLink?.link}
                    className="text-[#999999] hover:text-[#333333] mb-1 lg:mb-0"
                  >
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        display: "block",
                        textTransform: "none",
                      }}
                    >
                      <Typography
                        color={
                          location.pathname === quickLink.link
                            ? colors.ThemeColor
                            : colors?.black
                        }
                        fontWeight={
                          location.pathname === quickLink.link ? 700 : 400
                        }
                        variant={typographyOptions.H1}
                      >
                        {quickLink.text}
                      </Typography>
                    </Button>
                  </Link>
                ))}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "25px",
                  flexGrow: 0,
                  alignItems: "center",
                }}
              >
                <Link to={screenRoutes.SEARCH}>
                  <img src={SearchIcon} alt="" />
                </Link>
                {!loggedIn ? (
                  <Link to={authRoutes.LOGIN}>
                    <Typography
                      variant={typographyOptions.SUB_HEADING}
                      color={colors.black}
                      sx={{
                        display: { xs: "none", md: "block" },
                      }}
                    >
                      {"Log in"}
                    </Typography>
                  </Link>
                ) : (
                  <></>
                )}

                {loggedIn ? (
                  <>
                    <Box
                      onClick={(e: any) => handleClick(e)}
                      sx={{ cursor: "pointer" }}
                    >
                      {userDetails?.userImageAsBase64 === "" ? (
                        <Avatar
                          sx={{
                            backgroundColor: " #20b08f",
                            color: "white",
                            width: "45px",
                            height: "45px",
                            borderRadius: "50%",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          {avatar}
                        </Avatar>
                      ) : (
                        <img
                          src={getImagefromBase64(
                            userDetails?.userImageAsBase64
                          )}
                          style={{
                            borderRadius: "30px",
                            objectFit: "cover",
                            width: "50px",
                            height: "50px",
                          }}
                        />
                      )}
                    </Box>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      sx={{
                        borderRadius: "20px",
                      }}
                    >
                      <Stack
                        sx={{
                          padding: "20px 10px",
                          gap: "10px",
                          width: "180px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                            padding: "0px 10px",
                          }}
                        >
                          {userDetails?.userImageAsBase64 === "" ? (
                            <Avatar
                              sx={{
                                backgroundColor: " #20b08f",
                                color: "white",
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              {avatar}
                            </Avatar>
                          ) : (
                            <img
                              src={getImagefromBase64(
                                userDetails?.userImageAsBase64
                              )}
                              style={{
                                borderRadius: "30px",
                                objectFit: "cover",
                                width: "50px",
                                height: "50px",
                              }}
                            />
                          )}

                          {!userDetails?.username && !userDetails?.name ? (
                            <Typography
                              variant={typographyOptions.H1}
                              color={colors.black}
                              sx={{ textDecoration: "underline" }}
                            >
                              +Add details
                            </Typography>
                          ) : !userDetails?.username && userDetails?.name ? (
                            <Stack>
                              {" "}
                              <Typography
                                variant={typographyOptions.H1}
                                color={colors.black}
                              >
                                +Add details
                              </Typography>
                              <Typography
                                variant={typographyOptions.SUB_HEADING}
                                color={colors.quatenaryGrey}
                              >
                                {userDetails?.username}
                              </Typography>
                            </Stack>
                          ) : userDetails?.username && !userDetails?.name ? (
                            <Stack>
                              <Typography
                                variant={typographyOptions.H1}
                                color={colors.black}
                              >
                                +Add username
                              </Typography>
                              <Typography
                                variant={typographyOptions.SUB_HEADING}
                                color={colors.quatenaryGrey}
                              >
                                {userDetails?.username}
                              </Typography>
                            </Stack>
                          ) : (
                            <Stack>
                              <Typography
                                variant={typographyOptions.H1}
                                color={colors.black}
                              >
                                {userDetails?.name}
                              </Typography>
                              <Typography
                                variant={typographyOptions.SUB_HEADING}
                                color={colors.tertiaryGrey}
                              >
                                {userDetails?.username}
                              </Typography>
                            </Stack>
                          )}
                        </Box>

                        <Divider />
                        <Typography
                          variant={typographyOptions.H1}
                          sx={{ padding: "0px 15px", cursor: "pointer" }}
                          onClick={() => {
                            handleClose();
                            localStorage.removeItem("Login_Token");
                            toast.success("User Logged out successfully");
                          }}
                        >
                          Log out
                        </Typography>
                      </Stack>
                    </Popover>
                  </>
                ) : (
                  <Link to={authRoutes.REGISTER}>
                    <CustomButton
                      title={en.signUp}
                      border={"3px solid #CCF4EC"}
                      customStyle={{ borderRadius: "35px" }}
                    />
                  </Link>
                )}
              </Box>
            </Box>
          </Hidden>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
