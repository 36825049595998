import axios from "axios";
import { BASE_URL, getApiUrls } from "../../../config/apiurls";
import { constructUrl } from "../../../utils/helpers";
import axiosInstance from "../../../config/customAxios";

// user sign in
const userSignIn = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().userSignIn;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

// user sign up
const userSignUp = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().userSignUp;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

// user sign up google
const userSignUpGoogle = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().userSignUpGoogle;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

// check if email exists
const checkEmailExists = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().emailExistsorNot;
    const apiUrl: string = constructUrl(url, payload);
    return axios.get(apiUrl).catch((error) => error);
  } catch (error) {
    return error;
  }
};

// user sign up google
const fetchUserData = (payload: any) => {
  try {
    const url: string = getApiUrls().fetchUserData;
    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${payload?.token}`,
        },
      })
      .catch((error) => error);
  } catch (error) {
    return error;
  }
};

//email and otp
const verifyReset = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().verifyReset;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const checkUserNameExists = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrls(payload?.name).checkUserNameExists;
    return axiosInstance.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const resendVerification = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().resentVerification;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

//send otp on email
const initiateResetPassword = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().initiateResetPassword;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const confirmResetPassword = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().confirmResetPassword;
    return axios.put(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const verifyAccountEmail = (payload: any) => {
  const token = localStorage.getItem("Login_Token") || "";
  const tokenWithoutQuotes = token.replace(/"/g, "");
  try {
    const url: string = BASE_URL + getApiUrls().verifyAccountEmail;
    return axiosInstance
      .post(url, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenWithoutQuotes}`,
        },
      })
      .catch((error) => error);
  } catch (error) {
    return error;
  }
};

export default {
  userSignIn,
  userSignUp,
  userSignUpGoogle,
  checkEmailExists,
  fetchUserData,
  verifyReset,
  checkUserNameExists,
  resendVerification,
  initiateResetPassword,
  confirmResetPassword,
  verifyAccountEmail,
};
