import { Box, Typography } from "@mui/material";
import { typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";


const ResponsiveSeparator = ({ text }: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "15px",
        width: "100%",
        flexWrap: "wrap", // Wrap the content on smaller screens
      }}
    >
      {/* Left Separator */}
      <Box
        sx={{
          width: { xs: "50px", sm: "100px", md: "120px" },
          height: "1px",
          background:
            "linear-gradient(270deg, rgba(204, 204, 204, 0) 0%, #CCCCCC 50%, rgba(204, 204, 204, 0) 100%)",
          border: "none",
        }}
      ></Box>
      {/* Text in the middle */}
      <Typography
        variant={typographyOptions.H1}
        color={colors.secondaryGrey}
        sx={{
          textAlign: "center",
          flex: 1,
        }}
      >
        {text}
      </Typography>
      {/* Right Separator */}
      <Box
        sx={{
          width: { xs: "50px", sm: "100px", md: "120px" },
          height: "1px",
          background:
            "linear-gradient(270deg, rgba(204, 204, 204, 0) 0%, #CCCCCC 50%, rgba(204, 204, 204, 0) 100%)",
          border: "none",
        }}
      ></Box>
    </Box>
  );
};
export default ResponsiveSeparator;
