import { Box, Container } from "@mui/material";
import { AuthImg, BackIcon } from "../../assests/exportImages";
import styles from "./index.module.css";
import SignIn from "./sections/SignIn";
import SignUp from "./sections/SignUp";
import { AuthKeys } from "../../constants";
import ForgotPassword from "./sections/ForgotPassword";
import SignUpWithEmail from "./sections/SignUpWithEmail";
import { TellUsBitMore } from "./sections/TellUsBitMore";
import { useCallback, useEffect, useState } from "react";
import EmailVerification from "./sections/EmailVerification";
import { PassIcon } from "../../components/atom/PassIcon";
import { useNavigate } from "react-router-dom";
import ResetPassWord from "./sections/ResetPassWord";
import { scrollToTop } from "../../utils/helpers";

const initialState = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  otpass: "",
  reEnterPassword: "",
  newPassword: "",
  userName: "",
  userNameExists: "",
};

const errorInitialState = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  reEnterPassword: "",
  newPassword: "",
  userName: "",
  userNameExists: "",
};

const Auth = ({ component }: any) => {
  const navigate = useNavigate();
  const [fields, setFields] = useState(initialState);
  const [errorFields, setErrorFields] = useState(errorInitialState);
  const [otp, setOtp] = useState("");
  const handleChange = useCallback(
    (field: string, value: any) => {
      setFields((prevState: any) => ({ ...prevState, [field]: value }));
      setErrorFields((prevState: any) => ({ ...prevState, [field]: "" }));
    },
    [setFields, setErrorFields]
  );

  // set initial state of otp on change OTP Feild
  useEffect(() => {
    setFields((prevState: any) => ({ ...prevState, otpass: otp }));
    setErrorFields((prevState: any) => ({ ...prevState, otpass: "" }));
  }, [otp]);

  // // Resend otp onClick button
  // const onClickResendOtp = (e: SyntheticEvent) => {
  //   e.preventDefault();
  //   setSeconds(120); // Reset timer to 120 seconds when resending OTP
  //   dispatch(
  //     manualBookingActions.resendOTP({
  //       token: registerUserDetails?.token,
  //     })
  //   );
  //   setFields((prevState: any) => ({ ...prevState, otpass: "" }));
  //   setOtp("");
  // };
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Box className={styles.AuthMain}>
      <img src={AuthImg} alt="image auth" className={styles.AuthImg} />

      <Container>
        <Box sx={{ display: "flex", gap: "20px", alignItems: "baseline" }}>
          <Box className={styles.flexLgHidden}>
            <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />
          </Box>
          <Box className={styles.AuthResponsive}>
            {component === AuthKeys.REGISTER ? (
              <SignUp />
            ) : component === AuthKeys.LOGIN ? (
              <SignIn
                handleChange={handleChange}
                fields={fields}
                setErrorFields={setErrorFields}
                errorFields={errorFields}
                initialState={initialState}
                setFields={setFields}
                setOtp={setOtp}
              />
            ) : component === AuthKeys.FORGOT_PASSWORD ? (
              <ForgotPassword
                handleChange={handleChange}
                fields={fields}
                setErrorFields={setErrorFields}
                errorFields={errorFields}
              />
            ) : component === AuthKeys.REGISTER_WITH_EMAIL ? (
              <SignUpWithEmail
                handleChange={handleChange}
                fields={fields}
                setErrorFields={setErrorFields}
                errorFields={errorFields}
                initialState={initialState}
                setFields={setFields}
                setOtp={setOtp}
              />
            ) : component === AuthKeys.TELL_US_MORE ? (
              <TellUsBitMore
                handleChange={handleChange}
                fields={fields}
                setErrorFields={setErrorFields}
                errorFields={errorFields}
                setFields={setFields}
              />
            ) : component === AuthKeys.EMAIL_VERIFICATION ? (
              <EmailVerification fields={fields} setOtp={setOtp} />
            ) : component === AuthKeys.RESET_PASSWORD ? (
              <ResetPassWord
                handleChange={handleChange}
                fields={fields}
                setErrorFields={setErrorFields}
                errorFields={errorFields}
                setFields={setFields}
              />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Auth;
