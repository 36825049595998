import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { typographyOptions } from "../../constants";
import { getStoredImages } from "../../helper";
import en from "../../locale/en.json";
import { CustomButton } from "../atom/CustomButton";
import styles from "../components.module.css";
import { Card } from "../molecule/Card";
import { SkeletonCard } from "../skeltonLoader/cardSkelton";
import CustomScroll from "./CustomScroll";

interface ICardsWithScroll {
  cards: any[];
  loadingAllImages: boolean;
  showbtn: boolean;
  heading: string;
  description?: string;
  handleViewallClick?: () => void;
  loading?: boolean;
  handleCardClick: any;
}

const CardsWithScroll: React.FC<ICardsWithScroll> = (props) => {
  const {
    cards,
    loadingAllImages,
    showbtn,
    heading,
    description,
    handleViewallClick,
    loading,
    handleCardClick,
  } = props;

  const containerRef = useRef(null);

  return (
    <Box
      style={{
        backgroundColor: "#FAFAFA",
        position: "relative",
        padding: "15px 0px",
      }}
    >
      <Box className={styles.mainPopularTags}>
        {/* Position CustomScroll in front of the heading */}
        <CustomScroll
          containerRef={containerRef}
          cards={cards}
          header={
            <Container>
              <Stack>
                <Typography variant={typographyOptions.CARDS_HEADING}>
                  {heading}
                </Typography>
                <Typography variant={typographyOptions.CARDS_DESCRIPTION}>
                  {description}
                </Typography>
              </Stack>
            </Container>
          }
          children={
            <Box sx={{ display: "flex" }}>
              {loading ? (
                Array.from({ length: 5 }, (_, i) => <SkeletonCard key={i} />)
              ) : (
                <>
                  {cards?.map((data, index: number) => (
                    <Card
                      key={data.id}
                      image={getStoredImages(data?.reference)}
                      data={data}
                      onClick={() => handleCardClick(data)}
                      loadingAllImages={loadingAllImages}
                    />
                  ))}
                </>
              )}
            </Box>
          }
        />

        {showbtn && (
          <Box style={{ margin: "auto" }}>
            <CustomButton
              variant="outlined"
              title={en.viewAll}
              border="1px solid #20B08F"
              fontSize="15px"
              onClick={handleViewallClick}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CardsWithScroll;
