import { SideClicks } from "./SideClicks";
import { Box, Skeleton, Typography } from "@mui/material";
import LocationUI from "../../../components/molecule/LocationUI";
import styles from "./destination.module.css";
import { typographyOptions } from "../../../constants";

export const HeaderDetailPage = ({ placeDetails, loadingDestination }) => {
  return (
    // paddingResponsiveDestination
    <Box>
      {/* headings and share icons */}
      <Box className={styles.headerDetailPageContainer}>
        {loadingDestination ? (
          <Skeleton
            variant="rectangular"
            width={150}
            height={70}
            className="rounded-lg"
          />
        ) : (
          <Box className={styles.headerTextContainer}>
            <Typography variant={typographyOptions.CARDS_HEADING}>
              {placeDetails?.name}
            </Typography>
            <LocationUI
              location={`${
                placeDetails?.locality ? placeDetails?.locality + "," : ""
              } ${placeDetails?.country}`}
            />
          </Box>
        )}
        {loadingDestination ? (
          <Skeleton
            variant="rectangular"
            width={100}
            height={100}
            className="rounded-lg mt-4 md:mt-0"
          />
        ) : (
          <Box className={styles.sideClicksContainer}>
            <SideClicks placeDetails={placeDetails} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
