import TermsOfUse from "../components/TermsOfUse/TermsOfUse";
import { AuthKeys } from "../constants";
import { authRoutes, screenRoutes } from "../constants/routeConstants";
import { CustomRoutes, UnAuthRoutes } from "../interface/commonInterface";
import { AuthScreen, HomeScreen, ItineraryDetailScreen } from "../screens";
import PrivacyPolicy from "../screens/PrivacyPolicy/PrivacyPolicy";
import TravelGuidesScreen from "../screens/TravelGuides/index";
import TravellerDetail from "../screens/TravllerDetails";
import { AllImagesPreview } from "../screens/components/DestinationDetail/AllImagesPreview";
import { DestinationDetail } from "../screens/components/DestinationDetail/DestinationDetail";
import GetApp from "../screens/components/GetApp";
import SearchPage from "../screens/searchPage";

// const HomeScreen = lazy(() => import("../screens/Home/index"));
// const TravelGuidesScreen = lazy(() => import("../screens/TravelGuides/index"));

export const unAuthRoutes: UnAuthRoutes[] = [
  {
    path: authRoutes.LOGIN,
    component: <AuthScreen component={AuthKeys.LOGIN} />,
    exact: true,
  },
  {
    path: authRoutes.REGISTER,
    component: <AuthScreen component={AuthKeys.REGISTER} />,
    exact: true,
  },
  {
    path: authRoutes.FORGOT_PASSWORD,
    component: <AuthScreen component={AuthKeys.FORGOT_PASSWORD} />,
    exact: true,
  },

  {
    path: authRoutes.REGISTER_WITH_EMAIL,
    component: <AuthScreen component={AuthKeys.REGISTER_WITH_EMAIL} />,
    exact: true,
  },
  {
    path: authRoutes.TELL_US_MORE,
    component: <AuthScreen component={AuthKeys.TELL_US_MORE} />,
    exact: true,
  },
  {
    path: authRoutes.EMAIL_VERIFICATION,
    component: <AuthScreen component={AuthKeys.EMAIL_VERIFICATION} />,
    exact: true,
  },
  {
    path: authRoutes.RESET_PASSWORD,
    component: <AuthScreen component={AuthKeys.RESET_PASSWORD} />,
    exact: true,
  },
];

export const customRoutes: CustomRoutes[] = [
  {
    path: screenRoutes.HOME,
    component: <HomeScreen />,
    // exact: false,
  },
  {
    path: screenRoutes.PRIVACY_POLICY,
    component: <PrivacyPolicy />,
    exact: false,
  },
  {
    path: screenRoutes.TERMS_OF_USE,
    component: <TermsOfUse />,
    exact: false,
  },
  {
    path: screenRoutes.GET_APP,
    component: <GetApp />,
    exact: false,
  },
  {
    path: screenRoutes.TRAVEL_GUIDES,
    component: <TravelGuidesScreen />,
    exact: false,
  },
  {
    path: screenRoutes.SEARCH,
    component: <SearchPage />,
    exact: false,
  },
  {
    path: screenRoutes.SEARCH_DETAIL,
    component: <HomeScreen />,
    exact: false,
  },
  {
    path: screenRoutes.ITINARY_DETAIL,
    component: <ItineraryDetailScreen />,
    exact: false,
  },
  {
    path: screenRoutes.TRAVELLER_DETAIL,
    component: <TravellerDetail />,
    exact: false,
  },
  {
    path: screenRoutes.DESTINATION_DETAIL,
    component: <DestinationDetail />,
    exact: false,
  },
  {
    path: screenRoutes.ERROR_PAGE,
    component: <HomeScreen />,
    exact: false,
  },
  {
    path: screenRoutes.ALL_IMAGES_PREVIEW,
    component: <AllImagesPreview />,
    exact: false,
  },
];
