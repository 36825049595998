import { Skeleton, Stack } from "@mui/material";

const SkeletonFeaturedUserLoader = () => (
  <Stack marginRight="15px">
    <Skeleton
      sx={{
        width: "170px",
        height: "280px",
        borderRadius: "118px",
      }}
    />

    <Stack gap="3px" alignItems="center">
      <Skeleton width="60%" />
      <Skeleton width="50%" />
    </Stack>
  </Stack>
);

export default SkeletonFeaturedUserLoader;
