import { styled } from "styled-components";
import {
  Box,
  Grid,
  LinearProgress,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import { ratingCategories, typographyOptions } from "../../../constants";
import { colors } from "../../../themes/colors";
import styles from "./destination.module.css";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: "100%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette?.grey[theme.palette?.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette?.mode === "light" ? "#eaae33" : "#308fe8",
  },
}));
export const Ratings = ({ placeDetails }) => {
  // Function to get average rating
  const getAvgRating = () => {
    const totalReviews = placeDetails.googleReviews.length;
    const sum = placeDetails.googleReviews.reduce(
      (acc: any, review: any) => acc + review.rating,
      0
    );
    return sum / totalReviews;
  };
  // Function to count ratings by each value
  const getRatingCount = (rating: any) => {
    return placeDetails.googleReviews.filter(
      (review: any) => review.rating === rating
    ).length;
  };
  // Function to calculate the percentage for progress bars
  const getProgressValue = (rating: any) => {
    const totalReviews = placeDetails.googleReviews.length;
    const count = getRatingCount(rating);
    return (count / totalReviews) * 100;
  };

  return (
    <Box className={styles.ratingsMain}>
      <Box>
        <Box>
          <Typography
            variant={typographyOptions.H1}
            sx={{ fontSize: "34px", lineHeight: "43px", textAlign: "center" }}
          >
            {getAvgRating().toFixed(1)}
          </Typography>
          <Typography
            variant={typographyOptions.H1}
            sx={{
              lineHeight: "17.57px",
              textAlign: "center",
            }}
          >
            /5
          </Typography>
        </Box>
        <Typography
          variant={typographyOptions.H1}
          sx={{
            fontSize: "12px",
            lineHeight: "15.06px",
            textAlign: "center",
          }}
        >
          {placeDetails?.googleReviews?.length ?? ""} Reviews
        </Typography>
      </Box>
      <Box style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {ratingCategories.map((category: any) => {
          const count = getRatingCount(category.rating);
          return (
            <Box key={category.rating}>
              <Grid
                container
                alignItems="center"
                spacing={2}
                sx={{ width: "250px" }}
              >
                <Grid item xs={3}>
                  <Typography
                    variant={typographyOptions.H1}
                    sx={{
                      textAlign: "left",
                      color: colors.transparentWhite,
                    }}
                  >
                    {category.label}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <BorderLinearProgress
                    variant="determinate"
                    value={getProgressValue(category.rating)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant={typographyOptions.H1}
                    sx={{
                      textAlign: "left",
                      color: colors.transparentWhite,
                    }}
                  >
                    {count}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
