import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AndriodDownload, AppleDownload } from "../atom/DownloadAppIcons";
import { QRModal } from "./QRModal";
import { Logo } from "../../assests/exportImages";
import { screenRoutes } from "../../constants/routeConstants";
import en from "../../locale/en.json";
import { quickLinksArr } from "../../helper";

const Footer = () => {
  const openGmail = () => {
    window.open("mailto:admin@escapekar.com?subject=Contact Us", "_blank");
  };

  const privacyPolicyArr = [
    { link: screenRoutes.TERMS_OF_USE, text: en.termsOfUse },
    { link: screenRoutes.PRIVACY_POLICY, text: en.privacyPolicy },
    { link: "#", text: en.contactUs, action: openGmail },
  ];

  const [open, setOpen] = useState<boolean>(false);
  const handleClose = () => setOpen(false);

  return (
    <div className="Footer flex flex-col h-full lg:h-[298px] w-full justify-between bg-[#EEF0F3] border-t border-t-[#F1F1F1] z-10">
      <div className="flex flex-col lg:flex-row h-full items-center justify-center lg:justify-between px-[80px] pt-10 lg:pt-0">
        <div className="hidden lg:flex flex-col items-start justify-start w-auto lg:w-[45%]">
          <a href="/" className="w-[70%] cursor-pointer">
            <Logo />
          </a>
        </div>
        <div className="flex flex-col lg:flex-row items-center lg:items-start justify-between w-auto lg:w-[80%] gap-[40px]">
          <div className="flex flex-col items-center lg:items-start mt-[0px] lg:mt-0 gap-[10px]">
            <p className="lg:flex text-[18px] font-bold leading-[28px] font-Mulish mb-1 lg:mb-0">
              {en.quickLinks}
            </p>
            {quickLinksArr.map((quickLink, index) => (
              <Link
                key={index}
                to={quickLink.link}
                className="text-[16px] font-normal font-Mulish text-[#999999] hover:text-[#333333] mb-1 lg:mb-0"
              >
                {quickLink.text}
              </Link>
            ))}
          </div>
          <div className="flex flex-col items-center lg:items-start mt-[0px] lg:mt-0 gap-[10px]">
            <p className="lg:flex text-[18px] font-bold leading-[28px] font-Mulish mb-1 lg:mb-0">
              {en.policy}
            </p>
            {privacyPolicyArr.map((policy, index) => (
              <span
                key={index}
                onClick={policy.action ? policy.action : undefined}
                className="text-[16px] font-normal font-Mulish text-[#999999] hover:text-[#333333] mb-1 lg:mb-0 cursor-pointer"
              >
                {policy.action ? (
                  policy.text
                ) : (
                  <Link to={policy.link}>{policy.text}</Link>
                )}
              </span>
            ))}
          </div>
          <div className="flex flex-col items-center lg:items-start justify-end lg:mt-0 mb-[28px] lg:pt-0 border-t lg:border-t-0 border-t-[#F1F1F1]">
            <p className="text-[18px] font-bold leading-[28px] font-Mulish mb-[16px] lg:mb-[24px]">
              {en.downloadApp}
            </p>
            <div className="flex flex-col items-center gap-[10px]">
              <div className="flex flex-row items-center gap-[10px]">
                <AndriodDownload />
                <AppleDownload />
              </div>
            </div>
          </div>
          <div className="flex sm:hidden flex-col items-center">
            <a href="/" className="w-[60%] cursor-pointer">
              <Logo />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between items-center h-full lg:h-[60px] border-t border-t-[#E1E6EE] px-[20px] md:px-[80px]">
        <p className="text-[12px] md:text-xs text-center lg:text-left font-normal font-Mulish text-[#999999] mb-5 lg:mb-0 mt-5 lg:mt-0">
          © 2024 Escape Planner Pvt Ltd. All rights reserved.
        </p>
        <div className="socialLinks flex flex-row items-center justify-end mb-[24px] lg:mb-0 gap-[22px]">
          <a
            href="https://www.instagram.com/escapekar/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Instagram"
          >
            <InstagramIcon
              width="18px"
              className="text-[#CCCCCC] hover:text-[#919191] cursor-pointer transition duration-500 ease-in-out hover:scale-105"
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100092510055231"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Facebook"
          >
            <FacebookIcon
              width="18px"
              className="text-[#CCCCCC] hover:text-[#919191] cursor-pointer transition duration-500 ease-in-out hover:scale-105"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/escapekar/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <LinkedInIcon
              width="18px"
              className="text-[#CCCCCC] hover:text-[#919191] cursor-pointer transition duration-500 ease-in-out hover:scale-105"
            />
          </a>
        </div>
      </div>

      <QRModal open={open} handleClose={handleClose} />
    </div>
  );
};

export default Footer;
