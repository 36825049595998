import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { customRoutes, unAuthRoutes } from "../utils/routes";

const MainRouter = () => {
  return (
    <Routes>
      {[...unAuthRoutes, ...customRoutes].map((route: any, key: number) => {
        console.log("sndjkasndasj", route);
        return <Route path={route.path} element={route.component} key={key} />;
      })}
    </Routes>
  );
};

export default MainRouter;
