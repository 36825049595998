import { Avatar, Box, Typography } from "@mui/material";

import { getFullName, getInitials } from "../../utils/helpers";
import styles from "../components.module.css";
import { SearchTypes, typographyOptions } from "../../constants";
import { PopularTripsDummy } from "../../assests/exportImages";
import { colors } from "../../themes/colors";

interface IRectangleCard {
  image: any;
  name: string;
  desc?: string;
  userName?: string;
  data?: any;
  type: any;
  handleClick: () => void;
}

export const RectangleCard = ({
  image,
  name,
  desc,
  userName,
  data,
  type,
  handleClick,
}: IRectangleCard) => {
  const getImage = () => {
    if (type === SearchTypes.Destination || type === SearchTypes.Itinerary) {
      if (image && Object.keys(image)?.length) return image?.config?.url;
      else return PopularTripsDummy;
    } else if (type === SearchTypes.Users) {
      if (data?.userImageAsBase64) return image;
      else return;
    }
  };

  const avatar = getInitials(getFullName(data?.name));

  return (
    <Box
      className={styles.horizontalCard}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      {type === SearchTypes.Users && !data?.userImageAsBase64 ? (
        <Avatar className={styles.avatarContainer}>{avatar}</Avatar>
      ) : (
        <img
          src={getImage()}
          alt="Small Image"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "16px",
            objectFit: "cover",
          }}
        />
      )}
      <Box>
        <Typography
          variant={typographyOptions.H1}
          fontSize="16px"
          color={colors.black}
        >
          {name}
        </Typography>

        <Typography variant={typographyOptions.SUB_HEADING}>
          {userName ? userName : desc}
        </Typography>
      </Box>
    </Box>
  );
};
