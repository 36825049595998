import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/atom/CustomButton";
import OTPField from "../../../components/atom/OTPFeild";
import { typographyOptions } from "../../../constants";
import { authRoutes } from "../../../constants/routeConstants";
import en from "../../../locale/en.json";
import {
  resendOtp,
  verifyAccountEmail,
  verifyReset,
} from "../../../redux/actions/authActions";
import { RootState } from "../../../redux/reducers";
import { colors } from "../../../themes/colors";
import { formatSecondsToTime, scrollToTop } from "../../../utils/helpers";

const EmailVerification = ({ fields, setOtp }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { type = "" } = location.state || {};
  const { loading } = useSelector((state: RootState) => state.authReducer);

  const [seconds, setSeconds] = useState<number>(60);

  const handleVerifyOtp = () => {
    if (type === "signUp") {
      //nned to pass token
      const payload = {
        otp: fields?.otpass,
        onSuccess: () => {
          navigate(authRoutes.TELL_US_MORE);
        },
      };
      dispatch(verifyAccountEmail(payload));
    } else {
      const payload = {
        email: fields.email,
        otp: fields?.otpass,
        onSuccess: () => {
          navigate(authRoutes.RESET_PASSWORD);
        },
      };
      dispatch(verifyReset(payload));
    }
  };

  const handleResendOtpClick = () => {
    const payload = {
      email: fields.email,
      onSuccess: () => {
        setSeconds(60);
        //will show toaster
      },
    };
    dispatch(resendOtp(payload));
  };

  useEffect(() => {
    if (seconds === 0) {
      setSeconds(0);
      return;
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds: number) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Stack gap="40px">
      <Typography variant={typographyOptions.AUTH_HEADING}>
        {en.emailVerification}
      </Typography>

      <Stack>
        <Typography variant={typographyOptions.H1} color={colors.secondaryGrey}>
          {en.sentYouACode} {fields?.email}{" "}
        </Typography>
        <Typography
          variant={typographyOptions.H1}
          color={colors.black}
          sx={{ textDecoration: "underLine", cursor: "pointer" }}
          onClick={() => {
            navigate(authRoutes.REGISTER_WITH_EMAIL);
          }}
        >
          Change Email
        </Typography>
      </Stack>

      {/* sign in form */}
      <Stack gap="20px">
        <OTPField
          separator={<span> </span>}
          value={fields.otpass}
          onChange={setOtp}
          length={4}
        />
        <Stack>
          <Typography
            variant={typographyOptions.H1}
            color={colors.secondaryGrey}
          >
            {en.didnteceiveCode}{" "}
            <span
              style={{
                color: seconds > 30 ? colors.secondaryGrey : colors.black,
                cursor: seconds <= 30 ? "pointer" : "not-allowed",
              }}
              onClick={handleResendOtpClick}
            >
              Resend{" "}
              {seconds !== 0 ? `(${formatSecondsToTime(seconds)})` : <></>}
            </span>
          </Typography>
        </Stack>
      </Stack>
      {/* sign in button */}
      <CustomButton
        variant="contained"
        title={en.verifyOtp}
        fontSize="16px"
        loading={loading}
        customStyle={{
          backgroundColor: colors.black,
          maxWidth: "420px",
          border: "2px solid #333333",
          borderRadius: "36px",
          "&:hover": {
            backgroundColor: colors.black,
            borderColor: colors.black,
            borderWidth: "0px",
            "& .MuiTypography-root": {
              color: colors.primaryWhite,
            },
          },
        }}
        customTypography={{
          color: colors.primaryWhite,
        }}
        onClick={handleVerifyOtp}
      />
    </Stack>
  );
};

export default EmailVerification;
