import { useState } from "react";
import {
  CarIcon,
  PopularTripsDummy,
  StarIcon,
} from "../../../assests/exportImages";
import { Box, Typography, Skeleton } from "@mui/material";
import styles from "./section.module.css";
import { typographyOptions } from "../../../constants";
import { colors } from "../../../themes/colors";
import {
  DescriptionCard,
  RatingsUI,
  TimeAndDistance,
} from "./smallSectionsDetailPage";

interface ItineraryDetails {
  trip: any;
  image: any;
  onClick: Function | any;
  loading?: boolean;
}

const ItenaryDetailCard = ({
  trip,
  image,
  onClick,
  loading = false,
}: ItineraryDetails) => {
  return (
    <Box onClick={onClick}>
      <Box className={styles.itenaryDetailsCard}>
        <Box className={styles.detailPageUI}>
          <img
            src={image?.config?.url || PopularTripsDummy}
            alt="place img"
            style={{
              width: "70px",
              height: "70px",
              borderRadius: "17px",
            }}
          />

          <Box>
            <Typography
              fontWeight={"700"}
              color={colors.transparentWhite}
              variant={typographyOptions.H2}
            >
              {trip.name ? trip.name : trip.startAddress}
            </Typography>
            <Box style={{ display: "flex", gap: "10px" }}>
              <Typography variant={typographyOptions.SUB_HEADING}>
                {trip?.place_type ? trip?.place_type : "Attraction"}
              </Typography>{" "}
            </Box>
          </Box>
        </Box>
        <RatingsUI trip={trip} />

        {/* Description -card */}
        {trip?.description && (
          <DescriptionCard description={trip?.description} />
        )}
      </Box>
      {(trip?.time || trip?.distance) && <TimeAndDistance trip={trip} />}
    </Box>
  );
};

export const ItenaryDetailCardSkelton = () => {
  return (
    <>
      {Array.from({ length: 6 }).map((_: any, index: number) => (
        <Box>
          <Box className={styles.itenaryDetailsCard}>
            <Box className={styles.detailPageUI}>
              <Skeleton animation="wave" height={"70px"} width={"70px"} />

              <Box>
                <Skeleton animation="wave" width={"170px"} />
                <Box style={{ display: "flex", gap: "10px" }}>
                  <Skeleton width={"70px"} animation="wave" />
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Skeleton width={"40px"} />
            </Box>

            <Box>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default ItenaryDetailCard;
