import React from "react";
// import { logPageView } from "../analytics";
import { useLocation } from "react-router-dom";
import Header from "../../components/template/Header";
import { PlanYourNextTrip } from "../../components/template/PlanYourNextTrip";
import { QRModal } from "../../components/template/QRModal";

const GetApp = () => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  // useEffect(() => {

  //   let obj = {
  //     name: "Get app",
  //     path: location.path,

  //   }
  //   logPageView(obj);
  // }, [location])

  return (
    <div className="Home flex flex-col min-h-screen h-full w-full justify-between bg-gradient-to-b from-[#E2FFF8] to-[#f1f1f1] ">
      <Header />
      <div className="mt-[100px]">
        <PlanYourNextTrip />
      </div>
      <QRModal open={open} handleClose={handleClose} />
    </div>
  );
};

export default GetApp;
