import { Box, IconButton } from "@mui/material";
import styles from "../components.module.css";

interface IArrowIcon {
  icon: any;
  onClick: () => void;
  disabled?: boolean;
}

export const ArrowIcon = (props: IArrowIcon) => {
  const { icon = <></>, onClick = () => {}, disabled, ...rest } = props;
  return (
    <IconButton onClick={onClick} disabled={disabled} {...rest}>
      <Box className={styles.arrowIconMain}>
        <Box className={styles.arrowIconIn}>
          <>{icon}</>
        </Box>
      </Box>
    </IconButton>
  );
};
