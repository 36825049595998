import { Box, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { DestinationDetailTypes, typographyOptions } from "../../../constants";
import {
  CallIcon,
  ShareIcon,
  VisitWebSiteicon,
} from "../../../assests/exportImages";
import styles from "./destination.module.css";

export const SideClicks = ({ placeDetails }) => {
  const params = useLocation();
  const typeOfTags = placeDetails?.tags;
  const [showPopup, setShowPopup] = useState(false);

  const handleVisitWebsiteClick = () => {
    window.open(placeDetails?.website, "_blank");
  };

  const handleShareClick = () => {
    let url = `http://escapekar.online${params?.pathname}`;

    // copy the current URL to the clipboard
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000); // hide popup after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleCallClick = (phoneNo) => {
    if (!isMobile) {
      return null; // or return something else for non-mobile users
    }
    window.location.href = `tel:${phoneNo}`;
  };

  return (
    <Box className={styles.linksResponsive}>
      {typeOfTags !== DestinationDetailTypes[typeOfTags] && (
        <>
          {placeDetails?.formatted_phone_number ? (
            <>
              <Box className={styles.hiddenLgFlex}>
                <Tooltip title={placeDetails?.formatted_phone_number}>
                  <Box className={styles.sideClicksBox}>
                    <img src={CallIcon} alt="share icon" />
                    <Typography
                      variant={typographyOptions.HEADING}
                      sx={{
                        fontWeight: "bold",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      Call
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>{" "}
              <Box className={styles.flexLgHidden}>
                <Box
                  className={styles.sideClicksBox}
                  onClick={() =>
                    handleCallClick(placeDetails?.formatted_phone_number)
                  }
                >
                  <img src={CallIcon} alt="share icon" />
                  <Typography
                    variant={typographyOptions.HEADING}
                    sx={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Call
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
          {placeDetails?.website ? (
            <Box
              className={styles.sideClicksBox}
              onClick={handleVisitWebsiteClick}
            >
              <img src={VisitWebSiteicon} alt="share icon" />
              <Typography
                variant={typographyOptions.HEADING}
                sx={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Visit Website
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
      <Box className={styles.sideClicksBox} onClick={handleShareClick}>
        <img src={ShareIcon} alt="share icon" />
        <Typography
          variant={typographyOptions.HEADING}
          sx={{
            fontWeight: "bold",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          Share
        </Typography>
      </Box>
      {showPopup && <Box className={styles.popup}>Link copied!</Box>}
    </Box>
  );
};
