import { authAction } from "../actionTypes";

// user sign in
export const signIn = (payload: any) => ({
  type: authAction.USER_SIGN_IN_REQUESTED,
  payload,
});

// user sign up email
export const signUp = (payload: any) => ({
  type: authAction.USER_SIGN_UP_REQUESTED,
  payload,
});

// user sign up with google
export const signUpGoogle = (payload: any) => ({
  type: authAction.USER_SIGN_UP_GOOGLE_REQUESTED,
  payload,
});

// user sign up with google
export const checkEmailExists = (payload: any) => ({
  type: authAction.CHECK_EMAIL_EXISTS_REQUESTED,
  payload,
});

// user sign up with google
export const fetchUserData = (payload: any) => ({
  type: authAction.FETCH_USER_DATA_REQUESTED,
  payload,
});

// FOR FORGOT PASSWORD
export const verifyReset = (payload: any) => ({
  type: authAction.VERIFY_RESET_PASS_REQUESTED,
  payload,
});

// user sign up with google
export const verifyAccountEmail = (payload: any) => ({
  type: authAction.VERIFY_ACCOUNT_EMAIL_REQUESTED,
  payload,
});
// user sign up with google
export const checkUserNameExists = (payload: any) => ({
  type: authAction.CHECK_USERNAME_EXISTS_REQUESTED,
  payload,
});

// user sign up with google
export const resendOtp = (payload: any) => ({
  type: authAction.RESEND_OTP_REQUESTED_REQUESTED,
  payload,
});

// SEND OTP
export const initiateResetPassword = (payload: any) => ({
  type: authAction.INITIATE_RESET_REQUESTED,
  payload,
});

export const confirmResetPassword = (payload: any) => ({
  type: authAction.CONFIRM_RESET_PASS_REQUESTED,
  payload,
});
