import { Box, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GoogleIcon } from "../../../assests/exportImages";
import { CustomButton } from "../../../components/atom/CustomButton";
import { typographyOptions } from "../../../constants";
import { authRoutes, screenRoutes } from "../../../constants/routeConstants";
import en from "../../../locale/en.json";
import { colors } from "../../../themes/colors";
import styles from "../index.module.css";
import { GoogleLogin } from "../../../components/molecule/GoogleLogin";
import { useEffect } from "react";
import { scrollToTop } from "../../../utils/helpers";
import ResponsiveSeparator from "../../../components/atom/ResponsiveSeperator";

const SignUp = () => {
  const navigate = useNavigate();
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <Stack gap="40px">
      <Typography variant={typographyOptions.AUTH_HEADING}>
        {en.signUpEscapekar}
      </Typography>
      <Stack gap="35px">
        {/* sign up with google */}
        <GoogleLogin label={en.signUpEscapekar} type="signUp" />

        <ResponsiveSeparator text="or" />
        <CustomButton
          variant="outlined"
          title={en.continueWithEmail}
          fontSize="16px"
          customStyle={{
            maxWidth: "420px",
            border: "2px solid #F1F1F1",
            borderRadius: "36px",
            "&:hover": {
              backgroundColor: colors.black,
              borderColor: colors.black,
              borderWidth: "0px",
              "& .MuiTypography-root": {
                color: colors.primaryWhite,
              },
            },
          }}
          customTypography={{
            color: colors.black,
          }}
          onClick={() => navigate(authRoutes.REGISTER_WITH_EMAIL)}
        />
        <Box className={styles.ProceedingText}>
          <Typography
            variant={typographyOptions.H1}
            color={colors.secondaryGrey}
            lineHeight="20px"
          >
            {"By proceeding you agree to our "}

            <span
              style={{
                textDecoration: "underline",
                color: colors.black,
                marginRight: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(screenRoutes.TERMS_OF_USE);
              }}
            >
              {"Terms of Use"}
            </span>
            {"and confirm you have read our"}
            <span
              style={{
                textDecoration: "underline",
                color: colors.black,
                marginLeft: "5px",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(screenRoutes.PRIVACY_POLICY);
              }}
            >
              {"Privacy Policy."}
            </span>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <Typography
            variant={typographyOptions.H1}
            color={colors.secondaryGrey}
          >
            {"Already Have an Account?"}
          </Typography>
          <Typography
            variant={typographyOptions.H1}
            color={colors.black}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              navigate(authRoutes.LOGIN);
            }}
          >
            {en.signIn}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default SignUp;
