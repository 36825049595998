import { Container } from "@mui/material";
import { useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CardsWithScroll from "../../components/template/CardsWithScroll";
import Footer from "../../components/template/Footer";
import Header from "../../components/template/Header";
import { screenRoutes } from "../../constants/routeConstants";
import { getStoredImages } from "../../helper";
import en from "../../locale/en.json";
import { screenActionsCall } from "../../redux/actions";
import { logPageView } from "../../utils/analytics";
import AppDetails from "../components/AppDetails";
import DownloadAppCard from "../components/DownloadAppCard";
import { Explore } from "../components/Explore";
import FeaturedTravellers from "../components/FeaturedTravellers";
import { HiddenSpots } from "../components/HiddenSpots";
import { PopularAmongTravellersSection } from "../components/PopularAmongTravllersSection/PopularAmongTravllersSection";
import VacationList from "../components/VacationList";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { publishedTrips, loadingAllImages, publishedTripsLoader } =
    useSelector((state: any) => state.screenReducer);

  useEffect(() => {
    dispatch(screenActionsCall.getPublishedTrip());
    dispatch(screenActionsCall.getTrendingDestinations());
    dispatch(screenActionsCall.getFeaturedUsers());
    dispatch(screenActionsCall.getUserDetails());

    // google analytics on page mount
    let obj = {
      name: en.anayticsPageName.HOME,
      path: location.pathname,
    };
    logPageView(obj);
  }, []);

  const handlePopularTripsCardClick = (card: any) => {
    const image = getStoredImages(card.reference);
    navigate(`/itinerary-detail/${card.id}`, { state: { card, image } });
  };

  return (
    <>
      <Helmet>
        {" "}
        <title>Escapekar: Trip Planner</title>
        <meta name="title" content="Escapekar: Trip Planner" />
        <meta
          name="description"
          content="Discover your next adventure with Escapekar, the ultimate trip planner. Effortlessly create and customize your travel itinerary, explore destinations, and get personalized recommendations to make every journey unforgettable."
        />
        <meta name="url" content="https://escapekar.com/" />
        <meta
          name="keywords"
          content="hidden gems, best hidden gems, secret place, Diveagar Beach Maharashtra, Kollukumalai, Mandvi Gujarat, trip planner AI, AI travel, travel itinerary, travel planning, trip planner, AI trip planner, trip plans, artificial intelligence trip planning"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Trip Planner - Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover your next adventure with Escapekar, the ultimate trip planner. Effortlessly create and customize your travel itinerary, explore destinations, and get personalized recommendations to make every journey unforgettable."
        />
        <meta property="og:site_name" content="Escapekar: Trip Planner" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="in" />
      </Helmet>
      <div className="Home flex flex-col min-h-screen h-full w-full justify-between mt-10 gap-[90px]">
        <div
          style={{
            background:
              "linear-gradient(180deg, #E2FFF8 14.25%, rgba(255, 255, 255, 0) 100%)",
          }}
        >
          <Header boxShadow={"none"} />
          <Explore />
        </div>
        {/* popular trips */}
        <CardsWithScroll
          heading={en.popularTrips}
          description={en.diveIntoItinerariesCustomersText}
          cards={publishedTrips?.trips}
          showbtn={true}
          loadingAllImages={loadingAllImages}
          handleViewallClick={() => navigate(screenRoutes.TRAVEL_GUIDES)}
          loading={publishedTripsLoader}
          handleCardClick={handlePopularTripsCardClick}
        />

        <Container>
          <DownloadAppCard />

          <PopularAmongTravellersSection />

          <VacationList />
        </Container>

        <FeaturedTravellers />

        <HiddenSpots />
        <Container>
          <AppDetails />
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Home;
