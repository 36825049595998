import { Box, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/atom/CustomButton";
import Input from "../../../components/atom/Input";
import { GoogleLogin } from "../../../components/molecule/GoogleLogin";
import { typographyOptions } from "../../../constants";
import { authRoutes, screenRoutes } from "../../../constants/routeConstants";
import en from "../../../locale/en.json";
import { colors } from "../../../themes/colors";
import { signIn } from "../../../redux/actions/authActions";
import {
  checkWhiteSpace,
  scrollToTop,
  validateEmail,
  validatePassword,
} from "../../../utils/helpers";
import { useEffect } from "react";
import { RootState } from "../../../redux/reducers";
import ResponsiveSeparator from "../../../components/atom/ResponsiveSeperator";
import { screenActionsCall } from "../../../redux/actions";
import { toast } from "react-toastify";

const SignIn = ({
  fields,
  errorFields,
  handleChange,
  setErrorFields,
  initialState,
  setFields,
  setOtp,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading } = useSelector((state: RootState) => state.authReducer);

  const signInSuccessCallback = () => {
    dispatch(screenActionsCall.getUserDetails());
    toast.success("User LoggedIn out successfully");
    navigate(screenRoutes.HOME);
  };

  const handleSignInClick = () => {
    const emailCheck = validateEmail(fields.email);

    if (!emailCheck.status) {
      setErrorFields((prevState: any) => ({
        ...prevState,
        email: emailCheck.error,
      }));
      return;
    }

    const passwordCheck = validatePassword(fields.password);
    if (!passwordCheck.status) {
      setErrorFields((prevState: any) => ({
        ...prevState,
        password: passwordCheck.error,
      }));
      return;
    }

    const payload = {
      data: {
        email: fields.email,
        password: fields.password,
      },
      signInSuccessCallback,
    };

    dispatch(signIn(payload));
  };

  useEffect(() => {
    setFields((prevState: any) => ({
      ...prevState,
      email: initialState.email,
      password: initialState.password,
      otp: initialState.otp,
    }));
    setOtp("");
    scrollToTop();
  }, []);

  return (
    <Stack gap="40px">
      <Typography variant={typographyOptions.AUTH_HEADING}>
        {en.signEscapekar}
      </Typography>
      <Stack gap="20px">
        {/* sign in with google */}
        <GoogleLogin />

        <ResponsiveSeparator text="or sign in with email" />
        {/* sign in form */}
        <Stack gap="20px">
          <Stack gap="10px">
            <Typography
              variant={typographyOptions.H1}
              color={colors.primaryGrey}
              paddingLeft="10px"
            >
              {en.enterEmail}
            </Typography>
            <Input
              value={fields.email}
              onChange={(e: any) => {
                checkWhiteSpace(e.target.value) &&
                  handleChange("email", e.target.value);
              }}
              errormessage={errorFields?.email}
            />
          </Stack>
          <Stack gap="10px">
            <Typography
              variant={typographyOptions.H1}
              color={colors.primaryGrey}
              paddingLeft="10px"
            >
              {en.password}
            </Typography>
            <Input
              passwordInput={true}
              value={fields?.password}
              onChange={(e: any) => {
                checkWhiteSpace(e.target.value) &&
                  handleChange("password", e.target.value);
              }}
              errormessage={errorFields?.password}
            />
          </Stack>
          <Typography
            variant={typographyOptions.H1}
            color={colors.black}
            sx={{
              textAlign: "right",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(authRoutes.FORGOT_PASSWORD);
            }}
          >
            {en.forgotPassword}
          </Typography>
        </Stack>
        {/* sign in button */}
        <CustomButton
          variant="contained"
          title={en.signIn}
          fontSize="16px"
          loading={loading}
          customStyle={{
            backgroundColor: colors.black,
            maxWidth: "420px",
            border: "2px solid #333333",
            borderRadius: "36px",
            "&:hover": {
              backgroundColor: colors.black,
              borderColor: colors.black,
              borderWidth: "0px",
              "& .MuiTypography-root": {
                color: colors.primaryWhite,
              },
            },
          }}
          customTypography={{
            color: colors.primaryWhite,
          }}
          onClick={handleSignInClick}
        />
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <Typography
            variant={typographyOptions.H1}
            color={colors.secondaryGrey}
          >
            {en.dontHaveAccount}
          </Typography>
          <Typography
            variant={typographyOptions.H1}
            color={colors.black}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => {
              navigate(authRoutes.REGISTER);
            }}
          >
            {en.signUp}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default SignIn;
