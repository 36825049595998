import { Box, Skeleton, Typography } from "@mui/material";
import styles from "../components.module.css";
import { typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";
import { LocationIcon } from "../../assests/exportImages";

const LocationUI = ({ location, loading }: any) => {
  return (
    <Box className={styles.locationTagUi}>
      {loading ? (
        <Skeleton animation="wave" width="50px" height="18px" />
      ) : (
        <>
          <LocationIcon color={colors.ThemeColor} />
          <Typography
            variant={typographyOptions.SUB_HEADING}
            fontWeight="700"
            color={colors.ThemeColor}
          >
            {location}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default LocationUI;
