import { Box, Container, Stack, Typography } from "@mui/material";
import { AndriodDownload, AppleDownload } from "../atom/DownloadAppIcons";
import styles from "../components.module.css";
import en from "../../locale/en.json";
import { AppDetailsPic } from "../../assests/exportImages";
import { typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";

export const PlanYourNextTrip = (props: any) => {
  const { sx } = props;
  return (
    // <div className="bothSidesPadding">
    <Container>
      <Box className={styles?.GetAppContainer} sx={sx}>
        <Box>
          <img src={AppDetailsPic} alt="App" />
        </Box>
        <Box className={styles.contentGetApp}>
          <Stack gap="5px">
            <Typography variant={typographyOptions.EXTRA_LARGE}>
              {en.planYourNextTripWith}
            </Typography>
            <Typography
              variant={typographyOptions.EXTRA_LARGE}
              color={colors.ThemeColor}
            >
              {en.escapekar}
            </Typography>
          </Stack>
          <Typography
            variant={typographyOptions.H2}
            color={colors.secondaryGrey}
          >
            {en.YourTravelBuddyText}
          </Typography>
          <div className="flex flex-col items-center lg:items-start justify-end mt-[24px] lg:mt-0 mb-[28px] pt-[20px] lg:pt-0 border-t lg:border-t-0 border-t-[#F1F1F1]">
            <p className="text-[18px] font-bold leading-[28px] font-Mulish mb-[16px] lg:mb-[24px]">
              {en.downloadApp}
            </p>
            <div className="flex flex-row items-center gap-[10px]">
              <AndriodDownload />
              <AppleDownload />
            </div>
          </div>
        </Box>
      </Box>
    </Container>
    // </div>
  );
};
