import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "../../../components/atom/CustomButton";
import Input from "../../../components/atom/Input";
import { typographyOptions } from "../../../constants";
import { authRoutes, screenRoutes } from "../../../constants/routeConstants";
import en from "../../../locale/en.json";
import { confirmResetPassword } from "../../../redux/actions/authActions";
import { RootState } from "../../../redux/reducers";
import { colors } from "../../../themes/colors";
import {
  checkWhiteSpace,
  scrollToTop,
  validatePassword,
} from "../../../utils/helpers";

const ResetPassWord = ({
  handleChange,
  fields,
  setErrorFields,
  errorFields,
  setFields
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isEmailExists, failureMessage, loading } = useSelector(
    (state: RootState) => state.authReducer
  );

  const signUpSuccessCallback = () => {
    navigate(authRoutes.EMAIL_VERIFICATION);
  };

  const handleResetPassword = () => {
    const passwordCheck = validatePassword(fields.newPassword);
    if (!passwordCheck.status) {
      setErrorFields((prevState: any) => ({
        ...prevState,
        newPassword: passwordCheck.error,
      }));
      return;
    }

    if (fields?.newPassword !== fields?.reEnterPassword) {
      setErrorFields((prevState: any) => ({
        ...prevState,
        reEnterPassword: "Passwords do not match",
      }));
      return;
    }

    const payload = {
      email: fields.email,
      password: fields.reEnterPassword,
      otp: fields?.otp,
      onSuccess: () => {
        navigate(screenRoutes.HOME);
      },
    };

    dispatch(confirmResetPassword(payload));
  };

  useEffect(() => {
    if (!isEmailExists && failureMessage !== "") {
      setErrorFields((prevState: any) => ({
        ...prevState,
        email: failureMessage,
      }));
    }
  }, [failureMessage, isEmailExists]);

  useEffect(() => {
    setFields((prevState: any) => ({
      ...prevState,
      newPassword: "",
      reEnterPassword: "",
    }));
    scrollToTop();
  }, []);

  return (
    <Stack gap="40px">
      <Typography variant={typographyOptions.AUTH_HEADING}>
        {en.createNewPassword}
      </Typography>
      <Stack gap="20px">
        {/* sign up form */}
        <Stack gap="20px">
          <Stack gap="10px">
            <Typography
              variant={typographyOptions.H1}
              color={colors.primaryGrey}
              paddingLeft="10px"
            >
              {en.newPassword}
            </Typography>
            <Input
              passwordInput={true}
              value={fields?.newPassword}
              onChange={(e: any) => {
                checkWhiteSpace(e.target.value) &&
                  handleChange("newPassword", e.target.value);
              }}
              errormessage={errorFields?.newPassword}
            />
          </Stack>
          <Stack gap="10px">
            <Typography
              variant={typographyOptions.H1}
              color={colors.primaryGrey}
              paddingLeft="10px"
            >
              {en.reEnterPassword}
            </Typography>
            <Input
              passwordInput={true}
              value={fields?.reEnterPassword}
              onChange={(e: any) => {
                checkWhiteSpace(e.target.value) &&
                  handleChange("reEnterPassword", e.target.value);
              }}
              errormessage={errorFields?.reEnterPassword}
            />
          </Stack>
        </Stack>
        {/* sign in button */}
        <CustomButton
          variant="contained"
          title={en.changePassword}
          fontSize="16px"
          customStyle={{
            backgroundColor: colors.black,
            maxWidth: "420px",
            border: "2px solid #333333",
            borderRadius: "36px",
            "&:hover": {
              backgroundColor: colors.black,
              borderColor: colors.black,
              borderWidth: "0px",
              "& .MuiTypography-root": {
                color: colors.primaryWhite,
              },
            },
          }}
          customTypography={{
            color: colors.primaryWhite,
          }}
          onClick={handleResetPassword}
          loading={loading}
        />
      </Stack>
    </Stack>
  );
};

export default ResetPassWord;
