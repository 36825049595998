import { Box, Typography } from "@mui/material";
import { TravelGuidesBG } from "../../assests/exportImages";
import OptionsInput from "../../components/atom/OptionsInput";
import { typographyOptions } from "../../constants";
import en from "../../locale/en.json";
import { colors } from "../../themes/colors";
import styles from "./components.module.css";

export const SearchItenairy = ({ handleChangeText, searchInput }) => {
  return (
    <Box
      className={styles.bgImgTravelGuides}
      sx={{
        backgroundImage: `url(${TravelGuidesBG})`,
      }}
    >
      <Box className="flex flex-col gap-1 mt-12 md:mt-5 md:gap-[40px]">
        <Box className={styles.searchItenairyHeaderIn}>
          <Typography
            variant={typographyOptions.CARDS_HEADING}
            color={colors.primaryWhite}
          >
            {en.fromDreamstoDestinations}
          </Typography>
          <Typography
            variant={typographyOptions.CARDS_DESCRIPTION}
            color={colors.tertiaryGrey}
            fontWeight="700"
          >
            {en.findThePerfectItineraryText}
          </Typography>
        </Box>
        <OptionsInput
          placeholderText={en.FindYourDreamItinerary}
          showSelect={false}
          textFeildValue={searchInput}
          sx={{ width: "100%" }}
          onChangeText={(e: any) => {
            handleChangeText(e.target.value);
          }}
        />
      </Box>
    </Box>
  );
};
