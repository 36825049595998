import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import screenReducer from "./screenReducer";

const rootReducers = combineReducers({
  screenReducer,
  authReducer
});

export default rootReducers;
export type RootState = ReturnType<typeof rootReducers>;
