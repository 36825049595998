import { Box, Typography } from "@mui/material";
import {
  AndriodDownload,
  AppleDownload,
} from "../../components/atom/DownloadAppIcons";
import styles from "./components.module.css";
import { typographyOptions } from "../../constants";
import { AppDetailsPic } from "../../assests/exportImages";

const AppDetails = () => {
  return (
    <Box>
      {/* <Box className="bothSidesPadding" style={{ paddingBottom: "5rem" }}> */}
      <Box className={styles.AppDetailsMain}>
        <Box className={styles.contentAppDetails}>
          <Typography
            variant={typographyOptions.CARDS_HEADING}
            fontSize="62px"
            lineHeight="66px"
          >
            Don’t listen to what we say, Go see
          </Typography>
          <Typography
            variant={typographyOptions.CARDS_DESCRIPTION}
            textAlign="left"
          >
            Your ultimate travel companion. Carries all the information you need
            while travelling.
          </Typography>
          <div className="flex flex-col items-center lg:items-start justify-end mt-[24px] lg:mt-0 mb-[28px] pt-[20px] lg:pt-0 border-t lg:border-t-0 border-t-[#F1F1F1]">
            <div className="flex flex-row items-center gap-[10px]">
              <AndriodDownload />
              <AppleDownload />
            </div>
          </div>
        </Box>
        <Box className={styles.imageContainerAppDetails}>
          <img src={AppDetailsPic} alt="App" />
        </Box>
      </Box>
    </Box>
    // </div>
  );
};

export default AppDetails;
