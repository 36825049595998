import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PopularTripsDummy } from "../../../assests/exportImages";
import stylesCommon from "../../../components/components.module.css";
import { typographyOptions } from "../../../constants";
import { getRandomIndex, getStoredImages } from "../../../helper";
import en from "../../../locale/en.json";
import styles from "./PopularAmongSection.module.css";
import { useSelector } from "react-redux";
import { ArrowIcon } from "../../../components/atom/IconArrow";
import { colors } from "../../../themes/colors";
import { screenRoutes } from "../../../constants/routeConstants";

export const PopularAmongTravellersSection = () => {
  const { trendingDestinationsList = {} } = useSelector(
    (state: any) => state.screenReducer
  );

  const containerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cardArr, setCardArr] = useState([]);
  const navigate = useNavigate();
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const handleNext = () => {
    if (currentIndex + 6 < trendingDestinationsList?.length) {
      setCurrentIndex(currentIndex + 6);
    }
    if (currentIndex + 13 > trendingDestinationsList?.length) {
      setCanScrollRight(false);
      setCanScrollLeft(true);
    }
  };

  const handlePrev = () => {
    if (currentIndex - 6 >= 0) {
      setCurrentIndex(currentIndex - 6);
    }
    if (currentIndex - 13 <= 0) {
      setCanScrollRight(true);
      setCanScrollLeft(false);
    }
  };

  const handleClick = (card: any) => {
    navigate(
      `${screenRoutes.DESTINATION_DETAIL}${card.structured_formatting.main_text}/${card?.place_id}`,
      {
        state: { card },
      }
    );
  };

  useEffect(() => {
    if (trendingDestinationsList?.length >= 6) {
      let mod = trendingDestinationsList?.length % 6;
      let quotient = Math.floor(trendingDestinationsList?.length / 6);
      let spliceArr = trendingDestinationsList?.slice(0, 6 * quotient);
      if (mod) {
        setCanScrollRight(true);
        let remainingCards = 6 - mod;

        let randomIndex = getRandomIndex(spliceArr, remainingCards);
        let listArr = trendingDestinationsList;
        for (let i = 0; i < randomIndex?.length; i++) {
          listArr.push(trendingDestinationsList[randomIndex[i]]);
        }
        setCardArr(listArr);
      }
    }
  }, [trendingDestinationsList]);

  return (
    // <Box className="bothSidesPadding">
    <Box className={styles.popularAmongTravellersWrapper}>
      <Box className={styles.popularAmongTravellersWrapperIn}>
        <Typography variant={typographyOptions.CARDS_HEADING}>
          {en.PopularAmongTravellers}
        </Typography>
        <Typography variant={typographyOptions.CARDS_DESCRIPTION}>
          {en.theseDestinationsEveryoneGotRadar}
        </Typography>
      </Box>
      {/* cards */}
      <Box style={{ width: "100%", overflow: "hidden", position: "relative" }}>
        <Typography variant={typographyOptions.BACKGROUNDTEXT}>
          {en.DESTINATIONS}
        </Typography>
        <Box ref={containerRef} className={styles.cardsWebPlacementScroll}>
          <CardsplacementWeb
            cards={cardArr?.slice(currentIndex, currentIndex + 6)}
            handleClick={handleClick}
          />
        </Box>

        <Box ref={containerRef} className={styles.cardList}>
          <Box className={styles.cardsPlacementMobile}>
            {trendingDestinationsList?.map((item: any, index: number) => (
              <Card
                key={index}
                width="170px"
                height="250px"
                item={item}
                image={getStoredImages(item?.imageReference)}
                onClick={() => handleClick(item)}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box className={stylesCommon.scrollIcon} style={{ marginTop: "10px" }}>
        <ArrowIcon
          icon={<ArrowBackIcon />}
          onClick={() => handlePrev()}
          disabled={!canScrollLeft}
        />
        <ArrowIcon
          icon={<ArrowForwardIcon />}
          onClick={() => handleNext()}
          disabled={!canScrollRight}
        />
      </Box>
    </Box>
    // </Box>
  );
};

const Card = (props: any) => {
  const { onClick, image, isCenter, width, item, height } = props;
  return (
    <Box className={styles?.popularAmongTravellersCard} onClick={onClick}>
      <Box
        className={stylesCommon.backgroundImg}
        style={{
          backgroundImage: `url(${
            image?.config?.url ? image?.config?.url : PopularTripsDummy
          })`,
          borderRadius: "20px",
          height: isCenter === "yes" ? "100%" : height,
          width: width,
          minHeight: "200px",
        }}
      >
        <Box className={styles.gradientOverlay}></Box>
        <Box className={styles.cardContent}>
          <Typography
            variant={typographyOptions.H2}
            fontSize="20px"
            color={colors.primaryWhite}
          >
            {item?.structured_formatting?.main_text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const CardsplacementWeb = ({ cards, handleClick }: any) => {
  return (
    <Box className={styles.cardsPlacementWeb}>
      <Stack gap="20px" width="100%">
        <>
          <Card
            item={cards[0]}
            image={getStoredImages(cards[0]?.imageReference)}
            onClick={() => handleClick(cards[0])}
          />
          <Box style={{ display: "flex", gap: "20px" }}>
            <Card
              item={cards[1]}
              image={getStoredImages(cards[1]?.imageReference)}
              onClick={() => handleClick(cards[1])}
            />
            <Card
              item={cards[2]}
              image={getStoredImages(cards[2]?.imageReference)}
              onClick={() => handleClick(cards[2])}
            />
          </Box>
        </>
      </Stack>

      <Card
        isCenter={"yes"}
        item={cards[3]}
        image={getStoredImages(cards[3]?.imageReference)}
        onClick={() => handleClick(cards[3])}
      />

      <Stack gap="20px" width="100%">
        <Card
          item={cards[4]}
          image={getStoredImages(cards[4]?.imageReference)}
          onClick={() => handleClick(cards[4])}
        />
        <Card
          item={cards[5]}
          image={getStoredImages(cards[5]?.imageReference)}
          onClick={() => handleClick(cards[5])}
        />
      </Stack>
    </Box>
  );
};
