import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../themes/colors";
import { SearchIcon } from "../../assests/exportImages";
import { typographyOptions } from "../../constants";

interface IOptionsInput {
  selectedOption?: string;
  placeholderText: string;
  showSelect: boolean;
  choice?: Array<string>;
  handleOptionChange?: Function;
  errorMessage?: string;
  textFeildValue?: string;
  onChangeText?: Function | any;
  sx?: any;
}
const OptionsInput = (props: IOptionsInput) => {
  const {
    selectedOption,
    showSelect = false,
    handleOptionChange,
    placeholderText = "Select Option",
    errorMessage,
    choice,
    textFeildValue,
    onChangeText,
    sx,
  } = props;

  const handleSelect = (data: any) => {
    handleOptionChange(data);
  };

  return (
    <div style={{ width: "100%", maxWidth: "840px" }}>
      <form noValidate autoComplete="off">
        <FormControl
          sx={{
            width: "100%",
            marginTop: "30px",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <TextField
            type="search"
            placeholder={placeholderText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="" />
                </InputAdornment>
              ),
              sx: {
                backgroundColor: colors.primaryWhite,
                borderRadius: "14px",
                width: "100%",
                minHeight: "40px",
                maxHeight: "50px",
                maxWidth: "840px",
                boxShadow: "0px 0px 24px 0px #00000014",
                fontSize: "16px",

                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              },
            }}
            value={textFeildValue || ""}
            onChange={onChangeText}
            {...sx}
            {...props}
          />
          {showSelect && (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {/* Vertical Separator Line */}
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  mx: 2,
                  height: "30px",
                  borderLeft: "2px solid #ddd",
                  position: "absolute",
                  right: "20%",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              />
              <Select
                value={selectedOption}
                displayEmpty
                disableUnderline
                inputProps={{
                  style: {
                    border: "none",
                  },
                }}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  position: "absolute",
                  right: "10px",
                  top: "38%",
                  transform: "translateY(-50%)",
                  border: "none",
                  padding: "0px",
                }}
                onChange={(e) => handleSelect(e.target.value)}
              >
                {choice.map((data: any, index: number) => (
                  <MenuItem value={data} key={index}>
                    <Typography variant={typographyOptions.SUB_HEADING}>
                      {data}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </FormControl>
      </form>
      {errorMessage && <Typography variant="error">{errorMessage}</Typography>}
    </div>
  );
};

export default OptionsInput;
