import { Avatar, Box, Rating, Typography } from "@mui/material";
import { typographyOptions } from "../../../constants";
import { colors } from "../../../themes/colors";
import styles from "./destination.module.css";
import { getFullName, getInitials } from "../../../utils/helpers";

export const RatingCard = ({ authour }: any) => {
  const avatar = getInitials(getFullName(authour?.author_name));
  return (
    <Box className={styles.reviewsCard}>
      <Box className={styles.reviewsCardHeader}>
        <Avatar className={styles.avatarStyle}>{avatar}</Avatar>
        <Box style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <Typography
            variant={typographyOptions.H1}
            sx={{ textAlign: "left", color: colors.transparentWhite }}
          >
            {authour?.author_name}
          </Typography>
          <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Rating
              name="read-only"
              value={authour?.rating}
              readOnly
              sx={{ fontSize: "20px" }}
            />
            <Typography
              variant={typographyOptions.H1}
              sx={{
                textAlign: "left",
                fontSize: "10px",
              }}
            >
              {authour?.time}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography
          variant={typographyOptions.H1}
          sx={{
            textAlign: "left",
            fontWeight: "500",
          }}
        >
          {authour?.text}
        </Typography>
      </Box>
    </Box>
  );
};
