import { useState } from "react";
import { formatDate } from "../../../utils/helpers";
import { Box, Typography } from "@mui/material";
import { CarIcon, StarIcon } from "../../../assests/exportImages";
import { PlacesTypeIcons } from "../../../constants";
import styles from "./section.module.css";
import { typographyOptions } from "../../../constants";
import { colors } from "../../../themes/colors";
import {
  DescriptionCard,
  RatingsUI,
  TimeAndDistance,
} from "./smallSectionsDetailPage";

const OtherTypeCard = ({ trip }) => {
  const getDate = (date: any) => {
    const dateFormat: any = formatDate(date, "string");
    return `${dateFormat?.time} , ${dateFormat?.month} ${dateFormat?.day}`;
  };
  return (
    <Box>
      <Box className={styles.itenaryDetailsCard}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Box className={styles.detailPageUI}>
            <img
              src={PlacesTypeIcons[trip?.type]}
              alt="place img"
              style={{
                width: "42px",
                height: "42px",
                borderRadius: "12px",
              }}
            />

            <Box>
              <Typography
                fontWeight={"700"}
                color={colors.transparentWhite}
                variant={typographyOptions.H2}
              >
                {trip.name ? trip.name : trip.startAddress}
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={"700"}
                color={colors.transparentWhite}
                variant={typographyOptions.H2}
              >
                {` ${getDate(trip?.startDate)}- ${getDate(trip?.endDate)}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <RatingsUI trip={trip} />

        {/* Description -card */}
        {trip?.reference && <DescriptionCard description={trip?.reference} />}
      </Box>
      {(trip?.time || trip?.distance) && <TimeAndDistance trip={trip} />}
    </Box>
  );
};

export default OtherTypeCard;
