import { useNavigate } from "react-router-dom";
import { getStoredImages } from "../../../utils/helpers";
import ImageSlider from "./ImageSlider";
import { Box } from "@mui/material";
import styles from "./destination.module.css";
import en from "../../../locale/en.json";
import { screenRoutes } from "../../../constants/routeConstants";

export const ViewAllImages = ({ placeDetailsPhotos, placeDetails }: any) => {
  const navigate = useNavigate();

  // Get valid images
  const images = placeDetailsPhotos
    ?.slice(0, 5) // Limit to 5 images
    .map((photo: any) => getStoredImages(photo?.photo_reference))
    .filter((image: any) => image?.config?.url);

  const handleViewAllImagesClick = () => {
    navigate(`/destinationDetail/gallery/${placeDetails?.name}`, {
      state: { placeDetailsPhotos, placeDetails },
    });
  };

  const getImagesStructure = (count: number | string) => {
    switch (count) {
      case 1:
        return (
          <Box sx={{ width: "100%" }}>
            <img
              src={images[0]?.config?.url}
              alt=""
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "20px",
                objectFit: "cover",
              }}
            />
          </Box>
        );

      case 2:
        return (
          <Box className={styles.viewAllImagesBox}>
            <Box className={styles.viewAllImagesOne}>
              <img
                src={images[0]?.config?.url}
                alt=""
                className={styles.destinationImageWrapper}
              />
            </Box>
            <Box className={styles.viewAllImagesOne}>
              <img
                src={images[1]?.config?.url}
                alt=""
                className={styles.destinationImageWrapper}
                style={{ borderRadius: "0px 20px 20px 0px" }}
              />
            </Box>
          </Box>
        );
      case 3:
        return (
          <Box className={styles.viewAllImagesBox}>
            <Box className={styles.viewAllImagesOne}>
              <img
                src={images[0]?.config?.url}
                alt=""
                className={styles.destinationImageWrapper}
              />
            </Box>
            <Box className={styles.viewAllImagesIn}>
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <img
                  src={images[1]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "48%",
                    borderRadius: "0px 20px 0px 0px",
                    objectFit: "cover",
                  }}
                />
                <img
                  src={images[2]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "49%",
                    borderRadius: "0px 0px 20px 0px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box className={styles.viewAllImagesIn} style={{ width: "100%" }}>
            <Box className={styles.viewAllImagesBox2}>
              <img
                src={images[0]?.config?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "48%",
                  borderRadius: "20px 0px 0px 0px",
                  objectFit: "cover",
                }}
              />
              <img
                src={images[1]?.config?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "49%",
                  borderRadius: "0px 0px 0px 20px",
                  objectFit: "cover",
                }}
              />
            </Box>

            <Box className={styles.viewAllImagesBox2}>
              <img
                src={images[2]?.config?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "48%",
                  borderRadius: "0px 20px 0px 0px",
                  objectFit: "cover",
                }}
              />
              <img
                src={images[3]?.config?.url}
                alt=""
                style={{
                  width: "100%",
                  height: "49%",
                  borderRadius: "0px 0px 20px 0px",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>
        );
      case 5:
        return (
          <Box className={styles.viewAllImagesBox}>
            <Box className={styles.viewAllImagesOne}>
              <img
                src={images[0]?.config?.url}
                alt=""
                className={styles.destinationImageWrapper}
              />
            </Box>
            <Box className={styles.viewAllImagesIn}>
              <Box className={styles.viewAllImagesBox2}>
                <img
                  src={images[1]?.config?.url}
                  alt=""
                  style={{ width: "100%", height: "48%" }}
                />
                <img
                  src={images[2]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "49%",
                  }}
                />
              </Box>

              <Box className={styles.viewAllImagesBox2}>
                <img
                  src={images[3]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "48%",
                    borderRadius: "0px 20px 0px 0px",
                  }}
                />
                <img
                  src={images[4]?.config?.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "49%",
                    borderRadius: "0px 0px 20px 0px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {images?.length ? (
        <Box className={styles.viewAllImagesMain}>
          <Box
            className={styles.hiddenLgFlex}
            sx={{
              width: "100%",
              paddingTop: "50px",
              paddingBottom: "20px",
            }}
          >
            {getImagesStructure(images?.length)}
          </Box>

          <Box className={styles.flexLgHidden} sx={{ marginBottom: "20px" }}>
            <ImageSlider
              images={images}
              onClick={() => handleViewAllImagesClick()}
            />
          </Box>

          <Box
            className={`${styles.viewAllImagesButton} ${styles.hiddenLgFlex}`}
            onClick={() => handleViewAllImagesClick()}
          >
            {en.viewAllImages}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
