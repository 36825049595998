import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { GoogleIcon } from "../../assests/exportImages";
import en from "../../locale/en.json";
import { fetchUserData, signUpGoogle } from "../../redux/actions/authActions";
import { RootState } from "../../redux/reducers";
import { colors } from "../../themes/colors";
import { CustomButton } from "../atom/CustomButton";
import { useNavigate } from "react-router";
import { screenRoutes } from "../../constants/routeConstants";
import { screenActionsCall } from "../../redux/actions";

export const GoogleLogin = ({ label, type }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (credentialResponse: any) => {
      // Extract the token from the response and use it for authentication or further API calls.
      const token = credentialResponse.access_token;
      dispatch(
        fetchUserData({
          token,
          onSuccess: () => {
            navigate(screenRoutes.HOME);
            dispatch(screenActionsCall.getUserDetails());
          },
        })
      );
    },
    onError: (error) => {
      console.error("Login failed:", error);
    },
  });
  return (
    <CustomButton
      variant="outlined"
      title={label ? label : en.signWithGoogle}
      startIcon={GoogleIcon}
      fontSize="16px"
      customStyle={{
        maxWidth: "420px",
        border: "2px solid #F1F1F1",
        borderRadius: "36px",
        "&:hover": {
          backgroundColor: colors.black,
          borderColor: colors.black,
          borderWidth: "0px",
          "& .MuiTypography-root": {
            color: colors.primaryWhite,
          },
        },
      }}
      customTypography={{
        color: colors.black,
      }}
      onClick={login}
    />
  );
};
