// CustomScroll.tsx
import React, { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";
import { ArrowIcon } from "../atom/IconArrow";
import styles from "../components.module.css";

interface CustomScrollProps {
  children: React.ReactNode;
  cards: any[];
  containerRef: any;
  header: React.ReactNode;
}

const CustomScroll: React.FC<CustomScrollProps> = ({
  children,
  containerRef,
  cards,
  header,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const scroll = (scrollOffset: number) => {
    containerRef.current.scrollLeft += scrollOffset;
  };

  const updateScrollButtons = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;

      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    }
  };

  const handleScroll = () => {
    setIsScrolled(containerRef.current.scrollLeft > 0);
    updateScrollButtons();
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);

      updateScrollButtons(); // Check scroll buttons on mount

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    updateScrollButtons(); // Update scroll buttons when cards are loaded
  }, [cards]);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {header}
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Box className={styles.scrollIcon}>
            <ArrowIcon
              icon={<ArrowBackIcon />}
              onClick={() => scroll(-100)}
              disabled={!canScrollLeft}
            />
            <ArrowIcon
              icon={<ArrowForwardIcon />}
              onClick={() => scroll(100)}
              disabled={!canScrollRight}
            />
          </Box>
        </Box>
      </Box>

      <Box
        className={`${styles.cardListContainer} ${
          isScrolled ? styles.removePadding : ""
        }`}
        style={{ marginTop: "50px" }}
      >
        <Box className={styles.cardListTravellers} ref={containerRef}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomScroll;
