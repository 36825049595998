export const LoadMapKitScript = (callback) => {
  if (window.mapkit) {
    callback();
    return;
  }
  const script = document.createElement('script');
  script.src = "https://maps.apple.com/sdk/js/3.0/MapKit.js";
  script.async = true;
  script.onload = callback;
  document.body.appendChild(script);
};