import { InputAdornment, MenuItem, Select } from "@mui/material";
import { colors } from "../../themes/colors";

interface ICustomSelect {
  labelId: string | number;
  id?: string | number | any;
  value: string | number;
  onChange?: (value: string | number) => void;
  placeholder: string;
  sx?: any;
  defaultKey?: string;
  adornmentText?: string;
  handleChange?: Function;
  disabled?: boolean;
  options: any;
}

const CustomSelect = ({
  labelId,
  id,
  value,
  options,
  onChange,
  placeholder,
  sx = {},
  adornmentText = "",
  defaultKey = "",
  handleChange,
  disabled = false,
  ...props
}: ICustomSelect) => {
  return (
    <Select
      id={id}
      value={value}
      displayEmpty
      sx={{
        width: { xs: "100%", sm: "240px" },
        borderRadius: "12px",
        height: "46px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.transparentWhite,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.ThemeColor,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: colors.ThemeColor,
          borderWidth: "2px",
        },
        fontSize: "14px",
        ...sx,
      }}
      startAdornment={
        adornmentText && (
          <InputAdornment position="start">{`${adornmentText}: ${
            value ? "" : ""
          }`}</InputAdornment>
        )
      }
      inputProps={{
        "aria-label": placeholder, // Accessibility
      }}
      {...props}
    >
      <MenuItem
        sx={{
          fontSize: "14px",
        }}
        value=""
        disabled
      >
        {placeholder}
      </MenuItem>
      {options?.map((option: any, index: number) => {
        return (
          <MenuItem
            value={defaultKey ? option[defaultKey] : option}
            key={index}
            onClick={() => {
              handleChange(option);
            }}
            sx={{
              "&:hover": {
                backgroundColor: colors.secondaryWhite,
                color: colors.transparentWhite,
                borderBottom: "1px solid #eaeaea",
              },
              fontSize: "14px",
            }}
          >
            {defaultKey ? option[defaultKey] : option}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default CustomSelect;
