import { Box, Container, Skeleton, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { BackIcon } from "../../../assests/exportImages";
import { PassIcon } from "../../../components/atom/PassIcon";
import AboutMeCard from "../../../components/molecule/AboutMeCard";
import Header from "../../../components/template/Header";
import { DestinationDetailTypes, typographyOptions } from "../../../constants";
import en from "../../../locale/en.json";
import { getPlaceDetails } from "../../../redux/actions/screenActions";
import { colors } from "../../../themes/colors";
import { clearLocalStorage, scrollToTop } from "../../../utils/helpers";
import DownloadAppCard from "../DownloadAppCard";
import CardsWithScroll from "./CardsWithScroll";
import styles from "./destination.module.css";
import { HeaderDetailPage } from "./HeaderDetailPage";
import { Ratings } from "./Ratings";
import { RatingCard } from "./Reviews";
import { ViewAllImages } from "./ViewAllImages";
import Helmet from "react-helmet";

export const DestinationDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { placeDetails = {}, loadingDestination } = useSelector(
    (state: any) => state.screenReducer
  );
  const { card } = location.state || {};

  const typeOfTags = placeDetails?.tags;

  useEffect(() => {
    scrollToTop();
    clearLocalStorage();
    dispatch(getPlaceDetails({ placeId: card?.place_id }));
  }, [card?.place_id]);

  return (
    <Box>
      <Helmet>
        {" "}
        <title>Escapekar: Best hidden gems</title>
        <meta name="title" content="Escapekar: Best hidden gems" />
        <meta
          name="description"
          content="Discover hidden gems like Diveagar Beach, Kollukumalai, and Mandvi with Escapekar. Our AI-powered trip planner helps you explore secret places and best hidden spots for your next adventure. Plan your trip effortlessly with personalized recommendations and explore destinations tailored to your interests."
        />
        <meta
          name="keywords"
          content="hidden gems, best hidden gems, secret place, Diveagar Beach Maharashtra, Kollukumalai, Mandvi Gujarat, trip planner AI, AI travel, travel itinerary, travel planning, trip planner, AI trip planner, trip plans, artificial intelligence trip planning"
        />
        <meta name="url" content="https://escapekar.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/" />
        <meta
          property="og:title"
          content="Trip Planner - Your Ultimate Travel Guide"
        />
        <meta
          property="og:description"
          content="Discover your next adventure with Escapekar, the ultimate trip planner. Effortlessly create and customize your travel itinerary, explore destinations, and get personalized recommendations to make every journey unforgettable."
        />
        <meta property="og:site_name" content="Escapekar: Trip Planner" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="in" />
        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TouristAttraction",
            name: "Escapekar : Trip Planner",
            alternateName: "Escapekar",
            url: "https://escapekar.com/",

            contactPoint: {
              "@type": "ContactPoint",
              // telephone: "+91 9599819940",
              // contactType: "customer service",
              // contactOption: "TollFree",
              areaServed: "IN",
              availableLanguage: "en",
            },
            sameAs: [
              "https://www.facebook.com/escapekar/",
              "https://www.instagram.com/escapekar/",
              "https://www.linkedin.com/company/escapekar/",
            ],
          })}
        </script>
      </Helmet>
      <Box className={styles.hiddenLgFlex}>
        <Header />
      </Box>
      <Box className={styles.destinationDetailMain}>
        <Box className={styles.destinationDetailMobileHeader}>
          <PassIcon icon={BackIcon} onClick={() => navigate(-1)} />
          <Typography variant={typographyOptions.H1} color={colors.black}>
            {en.goBack}
          </Typography>
        </Box>
        <Container>
          <HeaderDetailPage
            placeDetails={placeDetails}
            loadingDestination={loadingDestination}
          />
          {loadingDestination ? (
            <Skeleton variant="rectangular" width={"100%"} height={200} />
          ) : (
            <ViewAllImages
              placeDetailsPhotos={placeDetails?.photos}
              placeDetails={placeDetails}
            />
          )}

          {placeDetails?.editorial_summary?.overview ? (
            <>
              {loadingDestination ? (
                <Skeleton variant="rectangular" width={210} height={118} />
              ) : (
                <AboutMeCard
                  heading={`About ${placeDetails?.administrativeAreaLevel1}`}
                  about={placeDetails?.editorial_summary?.overview}
                  loading={loadingDestination}
                />
              )}
            </>
          ) : (
            <></>
          )}
        </Container>

        {/* popular trips */}
        <CardsWithScroll
          heading={
            typeOfTags === DestinationDetailTypes[typeOfTags]
              ? en.popularAttractions
              : en.nearByAttractions
          }
          cards={placeDetails?.attractions?.results}
          showbtn={true}
          loadingDestination={loadingDestination}
        />

        <Container>
          <DownloadAppCard />
        </Container>

        {/* popular trips */}
        {placeDetails?.hiddenPlaces && (
          <CardsWithScroll
            heading={en.hiddenGems}
            cards={placeDetails?.hiddenPlaces?.results}
            showbtn={true}
          />
        )}

        <Box className={styles.reviewRatings}>
          <Box>
            {placeDetails?.googleReviews?.length > 0 && (
              <Ratings placeDetails={placeDetails} />
            )}
          </Box>
          <Box className={styles.ratingCardsList}>
            {placeDetails?.googleReviews?.length > 0
              ? placeDetails?.googleReviews?.map((authour: any) => {
                  return <RatingCard authour={authour} />;
                })
              : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
