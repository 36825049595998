import { Box } from "@mui/material";
import styles from "../components.module.css";

interface IPassIcon {
  icon: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}
export const PassIcon = (props: IPassIcon) => {
  const { icon, onClick = () => {}, ...rest } = props;
  return (
    <Box className={styles.passIconMain} onClick={onClick} {...rest}>
      <Box className={styles.passIconIn}>
        <img src={icon} alt="img" />
      </Box>
    </Box>
  );
};
