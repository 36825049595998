import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { flattenItems, getStoredImages } from "../../helper";
import { IActionInterface } from "../../interfaces";
import { screenActions } from "../actions/actionTypes";
import screenServices from "../services/screenService";

function* fetchSingleImages(
  item: any,
  keyName: string
): Generator<unknown, void, unknown> {
  try {
    const reference = item[keyName];

    if (reference === null || reference === undefined) return;

    yield put({ type: screenActions.GET_IMAGES_REQUESTED, reference, item });
    try {
      // yield all(
      //   items?.map((item: any) => {
      //     return getImagesApiCall(item, extractReference);
      //   })
      // );
      const response: any = yield call(screenServices.getImages, reference);
      const images = response;

      if (response?.status === 200) {
        localStorage.setItem(reference, JSON.stringify(images));

        yield put({
          type: screenActions.GET_IMAGES_SUCCEEDED,
          payload: images,
          item,
        });
      } else {
        yield put({
          type: screenActions.GET_IMAGES_FAILED,
        });
      }
    } catch (imageError) {
      yield put({
        type: screenActions.GET_IMAGES_FAILED,
      });
    }
  } catch (error) {
    yield put({ type: screenActions.GET_IMAGES_FAILED });
  }
}

function* getImagesApiCall(
  item: any,
  extractReference: any
): Generator<unknown, void, unknown> {
  const reference = extractReference(item);

  if (getStoredImages(reference)) {
    yield put({
      type: screenActions.GET_IMAGES_SUCCEEDED,
    });
    return;
  }

  if (reference === null) return;
  yield put({ type: screenActions.GET_IMAGES_REQUESTED, reference });

  try {
    const response: any = yield call(screenServices.getImages, reference);
    if (response?.status === 200) {
      // Save images to local storage
      localStorage.setItem(`${reference}`, JSON.stringify(response));
      yield put({
        type: screenActions.GET_IMAGES_SUCCEEDED,
      });
      return response?.status;
    } else {
      yield put({
        type: screenActions.GET_IMAGES_FAILED,
        error: response,
      });
      return response?.status;
    }
  } catch (imageError) {
    yield put({
      type: screenActions.GET_IMAGES_FAILED,
      error: imageError,
    });
  }
}

// Fetch images and itinerary details for featured travellers
function* fetchImages(action: IActionInterface): any {
  const { items = [], extractReference = () => {} } = action.payload;
  try {
    const response = yield all(
      items?.map((item: any) => {
        return getImagesApiCall(item, extractReference);
      })
    );
    if (response?.length) {
      yield put({ type: screenActions.GET_ALL_IMAGES_SUCCEEDED });
    } else {
      yield put({ type: screenActions.GET_ALL_IMAGES_FAILED });
    }
  } catch (error) {
    yield put({ type: screenActions.GET_IMAGES_FAILED });
  }
}

function* getPublishedTripSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getPublishedTrip);
    if (response.status === 200) {
      const data = response.data;
      yield put({
        type: screenActions.GET_PUBLISHED_TRIP_SUCCEEDED,
        payload: data,
      });
      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: data.trips,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({ type: screenActions.GET_PUBLISHED_TRIP_FAILED });
    }
  } catch (error) {
    yield put({ type: screenActions.GET_PUBLISHED_TRIP_FAILED });
  }
}

function* getTrendingDestinationsSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getTrendingDestinationsList);
    if (response.status === 200) {
      const trendingDestinationsList = response.data.predictions;
      yield put({
        type: screenActions?.GET_TRENDING_DESTINATIONS_SUCCESS,
        payload: [...trendingDestinationsList],
      });

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: trendingDestinationsList,
          extractReference: (trip: object | any) => trip?.imageReference,
        },
      });
    } else {
      yield put({ type: screenActions.GET_TRENDING_DESTINATIONS_FAILED });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_TRENDING_DESTINATIONS_FAILED,
    });
  }
}

function* getFeaturedUsers(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getFeaturedUsersList);
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_FEATURED_USERS_SUCCESS,
        payload: response?.data?.data,
      });
    } else {
      yield put({ type: screenActions.GET_FEATURED_USERS_FAILED });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_FEATURED_USERS_FAILED,
    });
  }
}

function* getItineraryDetails(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getItineraryDetails,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_ITINERARY_DETAILS_SUCCESS,
        payload: response?.data,
      });

      const allItems = flattenItems(response?.data?.trip);

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: allItems,
          extractReference: (item: any) => item.photoReference,
        },
      });
    } else {
      yield put({ type: screenActions.GET_ITINERARY_DETAILS_FAILED });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_ITINERARY_DETAILS_FAILED,
    });
  }
}

function* getFeaturedUserDetails(action: IActionInterface): any {
  console.log(action, "wsmdwme");
  try {
    const response = yield call(
      screenServices.getFeaturedUserDetails,
      action.payload
    );
    if (response.status === 200) {
      const userDetails = response?.data;

      yield put({
        type: screenActions?.GET_FEATURED_USER_DETAILS_SUCCESS,
        payload: userDetails,
      });
    } else {
      yield put({ type: screenActions.GET_FEATURED_USER_DETAILS_FAILED });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_FEATURED_USER_DETAILS_FAILED,
    });
  }
}

function* getFeaturedPublishedTrip(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getFeaturedPublishedTrip,
      action.payload
    );
    if (response.status === 200) {
      const featuredTripsList = response.data;
      console.log(featuredTripsList, "mkmdkwmedk");
      yield put({
        type: screenActions?.GET_FEATURED_USER_PUBLISHED_TRIPS_SUCCESS,
        payload: featuredTripsList,
      });
      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: featuredTripsList?.trips,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED,
    });
  }
}

function* getSearchedItinerary(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getSearchedItinerary,
      action.payload
    );
    if (response.status === 200) {
      const searchItenairyList = response.data.data;
      yield put({
        type: screenActions?.GET_SEARCHED_ITINERARY_SUCCESS,
        payload: searchItenairyList,
      });

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: searchItenairyList,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_SEARCHED_ITINERARY_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_SEARCHED_ITINERARY_FAILED,
    });
  }
}

function* getSearchedDestinations(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getSearchedDestinations,
      action.payload
    );
    if (response.status === 200) {
      const searchDestinationList = response.data.data;
      yield put({
        type: screenActions?.GET_SEARCHED_DESTINATIONS_SUCCESS,
        payload: searchDestinationList,
      });

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: searchDestinationList,
          extractReference: (trip: any) => trip.imageReference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_SEARCHED_DESTINATIONS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_SEARCHED_DESTINATIONS_FAILED,
    });
  }
}

function* getPopularTrips(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getPopularTrips, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_POPULAR_TRIPS_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: screenActions.GET_POPULAR_TRIPS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_POPULAR_TRIPS_FAILED,
    });
  }
}

function* getWeekendGateways(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getWeekendGateways);
    if (response.status === 200) {
      const weekendGatewayList = response?.data?.data;
      yield put({
        type: screenActions.GET_WEEKENDS_GATEWAY_SUCCESS,
        payload: weekendGatewayList,
      });
      console.log("lklklkkk", weekendGatewayList);
      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: weekendGatewayList,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_WEEKENDS_GATEWAY_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_WEEKENDS_GATEWAY_FAILED,
    });
  }
}

function* getLongTrips(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getLongTrips);
    if (response.status === 200) {
      const longTripsList = response?.data?.data;
      yield put({
        type: screenActions?.GET_LONG_TRIPS_SUCCESS,
        payload: longTripsList,
      });
      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: longTripsList,
          extractReference: (trip: object | any) => trip?.reference,
        },
      });
    } else {
      yield put({
        type: screenActions.GET_LONG_TRIPS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_LONG_TRIPS_FAILED,
    });
  }
}

function* getSortedItinerary(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.getSortedItinerary,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_SORTED_ITINERARY_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: screenActions.GET_SORTED_ITINERARY_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_SORTED_ITINERARY_FAILED,
    });
  }
}

function* getPlaceDetails(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getPlaceDetails, action.payload);
    if (response.status === 200) {
      const placeDetailsList = response?.data?.result;
      yield put({
        type: screenActions?.GET_PLACE_DETAILS_SUCCESS,
        payload: placeDetailsList,
      });

      yield put({
        type: screenActions.GET_ALL_IMAGES_REQUESTED,
        payload: {
          items: placeDetailsList,
          extractReference: (trip: object | any) => trip?.photo_reference,
        },
      });

      for (
        let start = 0;
        start < placeDetailsList?.attractions?.results.length;
        start++
      ) {
        yield call(
          fetchSingleImages,
          placeDetailsList?.attractions?.results[start]?.photos[0],
          "photo_reference"
        );
      }

      for (
        let start = 0;
        start < placeDetailsList?.hiddenPlaces?.results.length;
        start++
      ) {
        yield call(
          fetchSingleImages,
          placeDetailsList?.hiddenPlaces?.results[start]?.photos[0],
          "photo_reference"
        );
      }
    } else {
      yield put({
        type: screenActions.GET_PLACE_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_PLACE_DETAILS_FAILED,
    });
  }
}

function* getCoordinates(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getCoordinates, action.payload);
    if (response.status === 200) {
      yield put({
        type: screenActions?.GET_COORDINATES_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: screenActions.GET_COORDINATES_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions?.GET_COORDINATES_FAILED,
    });
  }
}

function* getSearchUsersSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.searchUsers, action?.payload);
    if (response.status === 200) {
      const searchUsersList = response.data.data;
      yield put({
        type: screenActions.SEARCH_USERS_SUCCESS,
        payload: searchUsersList,
      });
      // yield call(
      //   fetchImages,
      //   searchUsersList,
      //   (trip) => trip.reference,
      //   "images"
      // );
    } else {
      yield put({
        type: screenActions.SEARCH_USERS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions.SEARCH_USERS_FAILED,
    });
  }
}

function* getUserDetailsSaga(action: IActionInterface): any {
  try {
    const response = yield call(screenServices.getUserDetails);
    if (response.status === 200) {
      yield put({
        type: screenActions.GET_USER_DETAILS_SUCCESS,
        payload: response.data,
      });
    } else {
      yield put({
        type: screenActions.GET_USER_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions.GET_USER_DETAILS_FAILED,
    });
  }
}

function* updateUserDetailsSaga(action: IActionInterface): any {
  try {
    const response = yield call(
      screenServices.updateUserDetails,
      action.payload
    );
    if (response.status === 200) {
      yield put({
        type: screenActions.UPDATE_USER_DETAILS_SUCCESS,
        payload: response.data.data,
      });
    } else {
      yield put({
        type: screenActions.UPDATE_USER_DETAILS_FAILED,
      });
    }
  } catch (error) {
    yield put({
      type: screenActions.UPDATE_USER_DETAILS_FAILED,
    });
  }
}

function* screenSaga() {
  yield takeLatest(
    screenActions.GET_PUBLISHED_TRIP_REQUESTED,
    getPublishedTripSaga
  );

  yield takeEvery(screenActions.GET_ALL_IMAGES_REQUESTED, fetchImages);

  yield takeLatest(
    screenActions.GET_TRENDING_DESTINATIONS_REQUESTED,
    getTrendingDestinationsSaga
  );

  yield takeLatest(
    screenActions.GET_FEATURED_USERS_REQUESTED,
    getFeaturedUsers
  );

  yield takeLatest(
    screenActions.GET_ITINERARY_DETAILS_REQUESTED,
    getItineraryDetails
  );

  yield takeLatest(
    screenActions.GET_FEATURED_USER_DETAILS_REQUESTED,
    getFeaturedUserDetails
  );

  yield takeLatest(
    screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED,
    getFeaturedPublishedTrip
  );

  yield takeLatest(
    screenActions.GET_SEARCHED_ITINERARY_REQUESTED,
    getSearchedItinerary
  );

  yield takeLatest(
    screenActions.GET_SEARCHED_DESTINATIONS_REQUESTED,
    getSearchedDestinations
  );

  yield takeLatest(screenActions.GET_POPULAR_TRIPS_REQUESTED, getPopularTrips);

  yield takeLatest(
    screenActions.GET_WEEKENDS_GATEWAY_REQUESTED,
    getWeekendGateways
  );

  yield takeLatest(screenActions.GET_LONG_TRIPS_REQUESTED, getLongTrips);

  yield takeLatest(
    screenActions.GET_SORTED_ITINERARY_REQUESTED,
    getSortedItinerary
  );

  yield takeLatest(screenActions.GET_PLACE_DETAILS_REQUESTED, getPlaceDetails);

  yield takeLatest(screenActions.GET_COORDINATES_REQUESTED, getCoordinates);

  yield takeLatest(screenActions.SEARCH_USERS_REQUESTED, getSearchUsersSaga);

  yield takeLatest(
    screenActions.GET_USER_DETAILS_REQUESTED,
    getUserDetailsSaga
  );

  yield takeLatest(
    screenActions.UPDATE_USER_DETAILS_REQUESTED,
    updateUserDetailsSaga
  );
}

export default screenSaga;
