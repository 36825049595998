import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { ModalQR } from "../../assests/exportImages";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

interface IQRModal {
  open: boolean;
  handleClose: () => void;
}

export const QRModal = (props: IQRModal) => {
  const { open = false, handleClose = () => {} } = props;
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box sx={style}>
          <img src={ModalQR} alt="modal QR" />
        </Box>
      </Modal>
    </div>
  );
};
