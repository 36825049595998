import { Avatar, Box, Container, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import stylesCommon from "../../components/components.module.css";
import SkeletonFeaturedUserLoader from "../../components/skeltonLoader/featuredUserCard";
import { typographyOptions } from "../../constants";
import { getFullName, getImagefromBase64, getInitials } from "../../helper";
import { colors } from "../../themes/colors";
import styles from "./components.module.css";
import { screenRoutes } from "../../constants/routeConstants";

const FeaturedTravellers = ({ bgColor }: any) => {
  const navigate = useNavigate();

  const { featuredUsers, featuredUsersLoader } = useSelector(
    (state: any) => state.screenReducer
  );

  const handleCardClick = (card: any) => {
    navigate(`/traveller-detail/${card.userId}`, { state: { card } });
  };

  return (
    <Box
      style={{
        backgroundColor: "#FAFAFA",
        background: bgColor
          ? "linear-gradient(180deg, #F0F4F9 0%, #FAFAFA 100%)"
          : "",
      }}
    >
      <Box className={stylesCommon.mainPopularTags}>
        <Container>
          <Stack alignItems="baseline">
            <Typography variant={typographyOptions.CARDS_HEADING}>
              Featured Travellers
            </Typography>
            <Typography variant={typographyOptions.CARDS_DESCRIPTION}>
              See the stories of travellers who are exploring the world in
              style!
            </Typography>
          </Stack>
        </Container>

        <Box className={stylesCommon.cardListTravellers}>
          {featuredUsersLoader ? (
            Array.from({ length: 6 }).map((_, index) => (
              <SkeletonFeaturedUserLoader key={index} />
            ))
          ) : (
            <>
              {featuredUsers?.map((data: any) => {
                return (
                  <Card
                    key={data.id}
                    data={data}
                    onClick={() => handleCardClick(data)}
                  />
                );
              })}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const Card = ({ data, onClick }: any) => {
  const imageSrc = getImagefromBase64(data?.userImageAsBase64);
  const avatar = getInitials(getFullName(data?.name));

  return (
    <Box
      key={data?.userId}
      className={styles?.travellerCard}
      onClick={() => onClick(data?.userId)}
    >
      <Box className={styles.imageContainerTraveller}>
        {data?.userImageAsBase64 === "" ? (
          <Avatar className={styles.featuredUserAvatar}>{avatar}</Avatar>
        ) : (
          <img
            src={imageSrc}
            alt="Featured Traveller"
            className={styles.travellerImage}
          />
        )}

        <Box className={styles.overlay}>
          <Typography variant={typographyOptions.H2} fontWeight="700">
            View Profile
          </Typography>
        </Box>
      </Box>
      <Box className={styles.travellersDesc}>
        <Typography variant={typographyOptions.H2} color={colors.black}>
          {data?.username}
        </Typography>
        <Typography variant={typographyOptions.SUB_HEADING}>
          {data?.tripsAssociatedCount} Itineraries
        </Typography>
      </Box>
    </Box>
  );
};

export default FeaturedTravellers;
