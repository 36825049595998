export enum authAction {
  USER_SIGN_IN_REQUESTED = "USER_SIGN_IN_REQUESTED",
  USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS",
  USER_SIGN_IN_FAILED = "USER_SIGN_IN_FAILED",

  USER_SIGN_UP_REQUESTED = "USER_SIGN_UP_REQUESTED",
  USER_SIGN_UP_SUCCESS = "USER_SIGN_UP_SUCCESS",
  USER_SIGN_UP_FAILED = "USER_SIGN_UP_FAILED",

  USER_SIGN_UP_GOOGLE_REQUESTED = "USER_SIGN_UP_GOOGLE_REQUESTED",
  USER_SIGN_UP_GOOGLE_SUCCESS = "USER_SIGN_UP_GOOGLE_SUCCESS",
  USER_SIGN_UP_GOOGLE_FAILED = "USER_SIGN_UP_GOOGLE_FAILED",

  CHECK_EMAIL_EXISTS_REQUESTED = "CHECK_EMAIL_EXISTS_REQUESTED",
  CHECK_EMAIL_EXISTS_SUCCESS = "CHECK_EMAIL_EXISTS_SUCCESS",
  CHECK_EMAIL_EXISTS_FAILED = "CHECK_EMAIL_EXISTS_FAILED",

  FETCH_USER_DATA_REQUESTED = "FETCH_USER_DATA_REQUESTED",
  FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS",
  FETCH_USER_DATA_FAILED = "FETCH_USER_DATA_FAILED",

  VERIFY_ACCOUNT_EMAIL_REQUESTED = "VERIFY_ACCOUNT_EMAIL_REQUESTED",
  VERIFY_ACCOUNT_EMAIL_SUCCESS = "VERIFY_ACCOUNT_EMAIL_SUCCESS",
  VERIFY_ACCOUNT_EMAIL_FAILED = "VERIFY_ACCOUNT_EMAIL_FAILED",

  CHECK_USERNAME_EXISTS_REQUESTED = "CHECK_USERNAME_EXISTS_REQUESTED",
  CHECK_USERNAME_EXISTS_SUCCESS = "CHECK_USERNAME_EXISTS_SUCCESS",
  CHECK_USERNAME_EXISTS_FAILED = "CHECK_USERNAME_EXISTS_FAILED",

  RESEND_OTP_REQUESTED_REQUESTED = "RESEND_OTP_REQUESTED_REQUESTED",
  RESEND_OTP_REQUESTED_SUCCESS = "RESEND_OTP_REQUESTED_SUCCESS",
  RESEND_OTP_REQUESTED_FAILED = "RESEND_OTP_REQUESTED_FAILED",

  INITIATE_RESET_REQUESTED = "INITIATE_RESET_REQUESTED",
  INITIATE_RESET_SUCCESS = "INITIATE_RESET_SUCCESS",
  INITIATE_RESET_FAILED = "INITIATE_RESET_FAILED",

  CONFIRM_RESET_PASS_REQUESTED = "CONFIRM_RESET_PASS_REQUESTED",
  CONFIRM_RESET_PASS_SUCCESS = "CONFIRM_RESET_PASS_SUCCESS",
  CONFIRM_RESET_PASS_FAILED = "CONFIRM_RESET_PASS_FAILED",

  VERIFY_RESET_PASS_REQUESTED = "VERIFY_RESET_PASS_REQUESTED",
  VERIFY_RESET_PASS_SUCCESS = "VERIFY_RESET_PASS_SUCCESS",
  VERIFY_RESET_PASS_FAILED = "VERIFY_RESET_PASS_FAILED",
}

export enum screenActions {
  GET_PUBLISHED_TRIP_REQUESTED = "GET_PUBLISHED_TRIP_REQUESTED",
  GET_PUBLISHED_TRIP_SUCCEEDED = "GET_PUBLISHED_TRIP_SUCCEEDED",
  GET_PUBLISHED_TRIP_FAILED = "GET_PUBLISHED_TRIP_FAILED",

  GET_IMAGES_REQUESTED = "GET_IMAGES_REQUESTED",
  GET_IMAGES_SUCCEEDED = "GET_IMAGES_SUCCEEDED",
  GET_IMAGES_FAILED = "GET_IMAGES_FAILED",

  GET_ALL_IMAGES_REQUESTED = "GET_ALL_IMAGES_REQUESTED",
  GET_ALL_IMAGES_SUCCEEDED = "GET_ALL_IMAGES_SUCCEEDED",
  GET_ALL_IMAGES_FAILED = "GET_ALL_IMAGES_FAILED",

  GET_TRENDING_DESTINATIONS_SUCCESS = "GET_TRENDING_DESTINATIONS_SUCCESS",
  GET_TRENDING_DESTINATIONS_FAILED = "GET_TRENDING_DESTINATIONS_FAILED",
  GET_TRENDING_DESTINATIONS_REQUESTED = "GET_TRENDING_DESTINATIONS_REQUESTED",

  GET_FEATURED_USERS_REQUESTED = "GET_FEATURED_USERS_REQUESTED",
  GET_FEATURED_USERS_SUCCESS = "GET_FEATURED_USERS_SUCCESS",
  GET_FEATURED_USERS_FAILED = "GET_FEATURED_USERS_FAILED",

  GET_ITINERARY_DETAILS_REQUESTED = "GET_ITINERARY_DETAILS_REQUESTED",
  GET_ITINERARY_DETAILS_SUCCESS = "GET_ITINERARY_DETAILS_SUCCESS",
  GET_ITINERARY_DETAILS_FAILED = "GET_ITINERARY_DETAILS_FAILED",

  GET_FEATURED_USER_DETAILS_REQUESTED = "GET_FEATURED_USER_DETAILS_REQUESTED",
  GET_FEATURED_USER_DETAILS_SUCCESS = "GET_FEATURED_USER_DETAILS_SUCCESS",
  GET_FEATURED_USER_DETAILS_FAILED = "GET_FEATURED_USER_DETAILS_FAILED",

  GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED = "GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED",
  GET_FEATURED_USER_PUBLISHED_TRIPS_SUCCESS = "GET_FEATURED_USER_PUBLISHED_TRIPS_SUCCESS",
  GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED = "GET_FEATURED_USER_PUBLISHED_TRIPS_FAILED",

  GET_SEARCHED_ITINERARY_REQUESTED = "GET_SEARCHED_ITINERARY_REQUESTED",
  GET_SEARCHED_ITINERARY_SUCCESS = "GET_SEARCHED_ITINERARY_SUCCESS",
  GET_SEARCHED_ITINERARY_FAILED = "GET_SEARCHED_ITINERARY_FAILED",

  GET_SEARCHED_DESTINATIONS_REQUESTED = "GET_SEARCHED_DESTINATIONS_REQUESTED",
  GET_SEARCHED_DESTINATIONS_SUCCESS = "GET_SEARCHED_DESTINATIONS_SUCCESS",
  GET_SEARCHED_DESTINATIONS_FAILED = "GET_SEARCHED_DESTINATIONS_FAILED",

  GET_SEARCHED_USERS_REQUESTED = "GET_SEARCHED_USERS_REQUESTED",
  GET_SEARCHED_USERS_SUCCESS = "GET_SEARCHED_USERS_SUCCESS",
  GET_SEARCHED_USERS_FAILED = "GET_SEARCHED_USERS_FAILED",

  GET_POPULAR_TRIPS_REQUESTED = "GET_POPULAR_TRIPS_REQUESTED",
  GET_POPULAR_TRIPS_SUCCESS = "GET_POPULAR_TRIPS_SUCCESS",
  GET_POPULAR_TRIPS_FAILED = "GET_POPULAR_TRIPS_FAILED",

  GET_WEEKENDS_GATEWAY_REQUESTED = "GET_WEEKENDS_GATEWAY_REQUESTED",
  GET_WEEKENDS_GATEWAY_SUCCESS = "GET_WEEKENDS_GATEWAY_SUCCESS",
  GET_WEEKENDS_GATEWAY_FAILED = "GET_WEEKENDS_GATEWAY_FAILED",

  GET_LONG_TRIPS_REQUESTED = "GET_LONG_TRIPS_REQUESTED",
  GET_LONG_TRIPS_SUCCESS = "GET_LONG_TRIPS_SUCCESS",
  GET_LONG_TRIPS_FAILED = "GET_LONG_TRIPS_FAILED",

  GET_SORTED_ITINERARY_REQUESTED = "GET_SORTED_ITINERARY_REQUESTED",
  GET_SORTED_ITINERARY_SUCCESS = "GET_SORTED_ITINERARY_SUCCESS",
  GET_SORTED_ITINERARY_FAILED = "GET_SORTED_ITINERARY_FAILED",

  GET_PLACE_DETAILS_REQUESTED = "GET_PLACE_DETAILS_REQUESTED",
  GET_PLACE_DETAILS_SUCCESS = "GET_PLACE_DETAILS_SUCCESS",
  GET_PLACE_DETAILS_FAILED = "GET_PLACE_DETAILS_FAILED",

  GET_COORDINATES_REQUESTED = "GET_COORDINATES_REQUESTED",
  GET_COORDINATES_SUCCESS = "GET_COORDINATES_SUCCESS",
  GET_COORDINATES_FAILED = "GET_COORDINATES_FAILED",

  SEARCH_USERS_REQUESTED = "SEARCH_USERS_REQUESTED",
  SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS",
  SEARCH_USERS_FAILED = "SEARCH_USERS_FAILED",

  GET_USER_DETAILS_REQUESTED = "GET_USER_DETAILS_REQUESTED",
  GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILED = "GET_USER_DETAILS_FAILED",

  UPDATE_USER_DETAILS_REQUESTED = "UPDATE_USER_DETAILS_REQUESTED",
  UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_FAILED = "UPDATE_USER_DETAILS_FAILED",
}
