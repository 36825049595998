import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { formatDate } from "../../helper";
import { Likes } from "../atom/Likes";
import styles from "../components.module.css";
import {
  calenderIcon,
  daysIcon,
  PopularTripsDummy,
  userIcon,
} from "../../assests/exportImages";
import { typographyOptions } from "../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { colors } from "../../themes/colors";

interface ICard {
  image: any;
  data: any;
  style?: Object;
  height?: number | string;
  onClick: Function;
  loadingAllImages: boolean;
}

export const Card = ({
  image,
  data,
  style,
  height,
  onClick,
  loadingAllImages,
}: ICard) => {
  const getDaysorDate = () => {
    let startDate: any = {};
    let endDate: any = {};
    if (data.days === 0) {
      startDate = formatDate(data.startDate, "md");
      endDate = formatDate(data.endDate, "md");
      return `${startDate?.day}-${endDate?.day} ${endDate?.month} ${endDate?.year}`;
    }
    return `${data?.days} days`;
  };

  return (
    <Box className={styles.card} style={style} onClick={() => onClick(data.id)}>
      {loadingAllImages ? (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ borderRadius: "8px", height: "180px" }}
        />
      ) : (
        <Box className={styles.backgroundImg}>
          <LazyLoadImage
            alt={data.name}
            effect="opacity"
            src={image?.config?.url || PopularTripsDummy}
            width="100%"
            style={{
              borderRadius: "8px",
              objectFit: "cover",
              height: height ? height : "202px",
            }}
          />
          <Likes likesCount={data?.likes} />
        </Box>
      )}

      <Stack alignItems="baseline" padding="0px 8px 8px 10px">
        <Typography
          variant={typographyOptions.H2}
          color={colors.transparentWhite}
        >
          {data.name}
        </Typography>
        <Box className={styles.cardDescription}>
          <Box className={styles.cardDescriptionItems}>
            <img src={calenderIcon} alt="Calendar" />
            <Typography variant={typographyOptions.SUB_HEADING}>
              {getDaysorDate()}
            </Typography>
          </Box>
          <Box className={styles.cardDescriptionItems}>
            <img src={daysIcon} alt="Days" />
            <Typography variant={typographyOptions.SUB_HEADING}>
              {data?.items} Places
            </Typography>
          </Box>
        </Box>
        {data?.associatedUsers?.[0]?.name && (
          <Box
            className={styles.cardDescriptionItems}
            style={{ marginTop: "8px" }}
          >
            <img src={userIcon} alt="User" />
            <Typography variant="subHeading">
              {data?.associatedUsers?.[0]?.name}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
