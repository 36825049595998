import { Box, Container, Stack, Typography, debounce } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.css";
import { getImagefromBase64, getStoredImages } from "../../helper";
import Header from "../../components/template/Header";
import { BackgroundVector } from "../../assests/exportImages";
import en from "../../locale/en.json";
import OptionsInput from "../../components/atom/OptionsInput";
import { SearchTypes, typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";
import { RectangleCard } from "../../components/template/RectangleCard";
import { screenRoutes } from "../../constants/routeConstants";
import {
  getFeaturedUsers,
  getPublishedTrip,
  getSearchDestinations,
  getSearchItinerary,
  getTrendingDestinations,
  searchUsers,
} from "../../redux/actions/screenActions";
import { RootState } from "../../redux/reducers";
import Helmet from "react-helmet";

const SearchPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>(
    SearchTypes.Destination
  );

  const {
    searchUserList,
    searchDestinationList,
    searchItenairyList,
    publishedTrips,
    featuredUsers,
    trendingDestinationsList,
    searchLoading,
    publishedTripsLoader,
  } = useSelector((state: RootState) => state.screenReducer);

  const choice = ["Destination", "Itinerary", "Users"];

  const getPlaceHolderText = () => {
    let placeHolder = "";
    if (selectedOption === SearchTypes.Destination) {
      placeHolder = en.searchDestination;
    } else if (selectedOption === SearchTypes.Itinerary)
      placeHolder = en.searchItinerary;
    else if (selectedOption === SearchTypes.Users) placeHolder = en.searchUsers;
    return placeHolder;
  };

  // Define the debounced search function
  const debouncedSearch = useCallback(
    debounce((searchInput) => {
      if (selectedOption === SearchTypes.Destination) {
        const payload = {
          location: searchInput,
        };
        dispatch(getSearchDestinations(payload));
      } else if (selectedOption === SearchTypes.Itinerary) {
        dispatch(getSearchItinerary({ queryParams: searchInput }));
      } else if (selectedOption === SearchTypes.Users) {
        dispatch(searchUsers(searchInput));
      }
    }, 1000),
    [selectedOption]
  );

  const handleSearch = (searchInput: string) => {
    setSearchInput(searchInput);
    if (searchInput.length > 2 || !searchInput) {
      debouncedSearch(searchInput);
    }
  };

  useEffect(() => {
    dispatch(getPublishedTrip());
    dispatch(getFeaturedUsers());
    dispatch(getTrendingDestinations());
  }, []);

  const listToShow = () => {
    const listToShowList = [];
    if (
      selectedOption === SearchTypes.Destination &&
      searchDestinationList?.predictions?.length > 0
    ) {
      listToShowList.push(...searchDestinationList?.predictions);
    } else if (
      selectedOption === SearchTypes.Itinerary &&
      searchItenairyList?.length > 0
    ) {
      listToShowList.push(...searchItenairyList);
    } else if (
      selectedOption == SearchTypes.Users &&
      searchUserList?.length > 0
    ) {
      listToShowList.push(...searchUserList);
    }
    return listToShowList;
  };

  const handleCardClick = (card: any) => {
    navigate(
      `/destination-detail/${card.structured_formatting.main_text}/${card.place_id}`,
      {
        state: { card },
      }
    );
  };

  const handleCardClickFeaturedTravellers = (card: any) => {
    navigate(`/traveller-detail/${card.userId}`, {
      state: { card },
    });
  };

  const handleCardClickItenairy = (card: any) => {
    const image = getStoredImages(card.reference);
    navigate(`/itinerary-detail/${card.id}`, {
      state: { card, image },
    });
    // dispatch(getItineraryDetails(card.id));
  };

  return (
    <>
      <Helmet>
        <title>Escapekar: Search Your Perfect Travel Itinerary</title>
        <meta
          name="title"
          content="Escapekar: Search Your Perfect Travel Itinerary"
        />
        <meta
          name="description"
          content="Search for the ideal travel itinerary, explore various destinations, and find popular travel options on Escapekar. Discover user-generated recommendations and top-rated travel plans to make your journey unforgettable."
        />
        <meta
          name="keywords"
          content="travel itinerary search, destination search, popular travel options, user-generated travel recommendations, top-rated travel plans, trip planning, search travel destinations"
        />

        <meta name="url" content="https://escapekar.com/search" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://escapekar.com/search" />
        <meta
          property="og:title"
          content="Search Your Perfect Travel Itinerary on Escapekar"
        />
        <meta
          property="og:description"
          content="Find the perfect travel itinerary, explore destinations, and see popular travel options with Escapekar's search functionality. Discover user recommendations and top travel plans."
        />
        <meta property="og:site_name" content="Escapekar" />
        <meta property="og:locale" content="en-IN" />
        <meta property="og:country-name" content="IN" />

        <script id="data" type="application/json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "SearchResultsPage",
            name: "Escapekar Search Results",
            description:
              "Search for travel itineraries, explore destinations, and find popular options on Escapekar. Discover user recommendations and top-rated travel plans.",
            url: "https://escapekar.com/search",

            // mainEntity: [
            //   {
            //     "@type": "SearchAction",
            //     target: "https://escapekar.com/search?q={search_term_string}",
            //     queryInput: "required name=search_term_string"
            //   }
            // ],
            hasPart: [
              {
                "@type": "ItemList",
                name: "Itineraries",
                description:
                  "Browse through various travel itineraries based on your search.",
              },
              {
                "@type": "ItemList",
                name: "Destinations",
                description:
                  "Explore destinations based on your search criteria.",
              },
              {
                "@type": "ItemList",
                name: "User Recommendations",
                description:
                  "Discover travel recommendations and plans shared by other users.",
              },
              {
                "@type": "ItemList",
                name: "Popular Travel Options",
                description:
                  "Find popular and top-rated travel plans and destinations.",
              },
            ],
          })}
        </script>
      </Helmet>
      <Header />
      <Box
        className={styles.backgroundImg}
        sx={{
          backgroundImage: `url(${BackgroundVector}) `,
          marginTop: "110px",
        }}
      >
        <OptionsInput
          placeholderText={getPlaceHolderText()}
          showSelect={true}
          textFeildValue={searchInput}
          choice={choice}
          sx={{ width: "100%" }}
          onChangeText={(e: any) => {
            handleSearch(e.target.value);
          }}
          handleOptionChange={(value: any) => {
            setSelectedOption(value);
            setSearchInput("");
          }}
        />

        <Box className={styles.searchPageMain}>
          {searchLoading ? (
            <CircularProgress
              variant="indeterminate"
              sx={{
                color: colors.ThemeColor,
              }}
              size={40}
              thickness={4}
            />
          ) : (
            <>
              {searchInput?.length > 2 ? (
                <>
                  {listToShow()?.length ? (
                    <>
                      {" "}
                      {listToShow()?.map((data, index) => (
                        <RectangleCard
                          data={data}
                          type={selectedOption}
                          image={
                            selectedOption === SearchTypes.Users
                              ? getImagefromBase64(data.userImageAsBase64)
                              : selectedOption === SearchTypes.Destination
                              ? getStoredImages(data.imageReference)
                              : getStoredImages(data.reference)
                          }
                          name={
                            selectedOption === SearchTypes.Destination
                              ? data?.structured_formatting?.main_text
                              : data?.name
                          }
                          userName={data?.username ?? data?.username}
                          desc={
                            selectedOption === SearchTypes.Destination
                              ? data?.structured_formatting?.secondary_text
                              : data?.desc || data.associatedUsers?.[0].name
                          }
                          //   days={data?.days ?? data?.days}
                          handleClick={() => {
                            selectedOption == SearchTypes.Users
                              ? handleCardClickFeaturedTravellers(data)
                              : selectedOption === SearchTypes.Destination
                              ? handleCardClick(data)
                              : handleCardClickItenairy(data);
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <Typography>{en.noDataFound}</Typography>
                  )}
                </>
              ) : selectedOption === SearchTypes.Destination ? (
                <>
                  <Typography
                    variant={typographyOptions.CARDS_HEADING}
                    // style={{ , padding: "0px 20px" }}
                    fontSize="24px"
                  >
                    {en.popularDestinations}
                  </Typography>
                  {trendingDestinationsList?.map((data: any) => (
                    <RectangleCard
                      image={getStoredImages(data.imageReference)}
                      name={data?.structured_formatting?.main_text}
                      desc={data?.structured_formatting?.secondary_text}
                      handleClick={() => handleCardClick(data)}
                      type={selectedOption}
                    />
                  ))}
                </>
              ) : selectedOption === SearchTypes.Itinerary ? (
                <>
                  <Typography
                    variant={typographyOptions.CARDS_HEADING}
                    // style={{ , padding: "0px 20px" }}
                    fontSize="24px"
                  >
                    {en.popularItenerary}
                  </Typography>

                  {publishedTrips?.trips?.map(
                    (
                      data: any // trips .
                    ) => (
                      <RectangleCard
                        image={getStoredImages(data.reference)}
                        name={data?.name}
                        desc={data?.associatedUsers[0]?.name} // need to pass data with user icon
                        handleClick={() => handleCardClickItenairy(data)}
                        type={selectedOption}
                        // days={data?.days} need to work on ui
                      />
                    )
                  )}
                </>
              ) : (
                <>
                  <Typography
                    variant={typographyOptions.CARDS_HEADING}
                    // style={{ , padding: "0px 20px" }}
                    fontSize="24px"
                  >
                    {en.featuredTravellers}
                  </Typography>
                  {featuredUsers?.map((data: any) => (
                    <RectangleCard
                      image={getImagefromBase64(data.userImageAsBase64)}
                      name={data?.name}
                      userName={data?.username ?? data?.username}
                      handleClick={() =>
                        handleCardClickFeaturedTravellers(data)
                      }
                      type={selectedOption}
                      data={data}
                    />
                  ))}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SearchPage;
