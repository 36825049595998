import React from "react";
import { Box, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { colors } from "../../themes/colors";
import styles from "../components.module.css";

interface DynamicTabs {
  tabs: any;
  value: string;
  onChange: Function | any;
}

const DynamicTabs = ({ tabs, value, onChange = () => {} }: DynamicTabs) => {
  return (
    <TabContext value={value}>
      <Box
        sx={{
          backgroundColor: colors.tertiaryGrey,
        }}
        className={styles.tabBackground}
      >
        <TabList
          onChange={onChange}
          aria-label="tabs"
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            borderRadius: "10px",
            minHeight: "30px",
            width: "100%",
            margin: "5px",
            height: "35px",
          }}
        >
          {tabs.map((tab: any) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              sx={{
                backgroundColor:
                  value === tab.value
                    ? colors.primaryWhite
                    : colors.tertiaryGrey,
                "&:hover": { backgroundColor: colors.primaryWhite },
                textTransform: "none",
                color:
                  value === tab.value ? colors.black : colors.secondaryGrey,
                fontWeight: value === tab.value ? 500 : "normal",
                width: "50%",
                height: "35px",
                minHeight: "30px",

                "&.Mui-selected": {
                  color: "unset",
                  height: "35px",
                  borderRadius: "10px",
                  minHeight: "30px",
                },
              }}
            />
          ))}
        </TabList>
      </Box>
      {tabs.map((tab: any) => (
        <TabPanel
          key={tab.value}
          value={tab.value}
          sx={{ padding: "22px 0px", width: "100%" }}
        >
          {tab.content}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default DynamicTabs;
