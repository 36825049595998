// src/store.js
import { configureStore, MiddlewareArray } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducers from "./reducers";
import { rootSaga } from "./saga";

let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducers,
  middleware: new MiddlewareArray().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

// let sagaMiddleware = createSagaMiddleware();
// const logger = (store: any) => (next: any) => (action: any) => {
//   let result = next(action);
// };

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: new MiddlewareArray().concat(sagaMiddleware, logger),
// });

export default store;
