import { AppleIcon, PlayStore } from "../../assests/exportImages";

export const AndriodDownload = () => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.escapekar.escapekar"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Google Play Store"
    >
      <div className="flex flex-row h-[52px] w-[138px] md:h-[56px] md:w-[170px] bg-[#000000] rounded-[10px] cursor-pointer justify-center transition duration-500 ease-in-out hover:scale-105">
        <img alt="Google Play" src={PlayStore} className="my-2" />
        <div className="flex flex-col items-start justify-center ml-[4px] md:ml-[10px] py-1 md:py-3">
          <p className="text-[9px] font-bold font-Mulish text-[#ffffff]">
            GET IT ON
          </p>
          <p className="text-sm md:text-base font-semibold font-Mulish text-[#ffffff]">
            Google Play
          </p>
        </div>
      </div>
    </a>
  );
};

export const AppleDownload = () => {
  return (
    <a
      href="https://apps.apple.com/in/app/escapekar-ai-trip-planner/id6446503558"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Apple App Store"
    >
      <div className="flex flex-row h-[52px] w-[138px] md:h-[56px] md:w-[170px] bg-[#000000] rounded-[10px] cursor-pointer justify-center transition duration-500 ease-in-out hover:scale-105">
        <img alt="App Store" src={AppleIcon} className="my-1" />
        <div className="flex flex-col items-start justify-center ml-[4px] md:ml-[10px] py-1 md:py-3">
          <p className="text-[9px] font-bold font-Mulish text-[#ffffff]">
            DOWNLOAD ON THE
          </p>
          <p className="text-sm md:text-base font-semibold font-Mulish text-[#ffffff]">
            App Store
          </p>
        </div>
      </div>
    </a>
  );
};
