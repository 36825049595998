import axios from "axios";
import { BASE_URL, getApiUrls } from "../../../config/apiurls";
import { constructUrl } from "../../../utils/helpers";
import axiosInstance from "../../../config/customAxios";

const getPublishedTrip = () => {
  try {
    const url: string = BASE_URL + getApiUrls().publishedTrips;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getImages = (id: string) => {
  try {
    const url: string = BASE_URL + getApiUrls(id).getImages;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getTrendingDestinationsList = () => {
  try {
    const url: string = BASE_URL + getApiUrls().getTrendingDestinationsList;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getFeaturedUsersList = () => {
  try {
    const url: string = BASE_URL + getApiUrls().getFeaturedUsers;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getItineraryDetails = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrls(payload?.tripId).getItineraryDetails;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getFeaturedUserDetails = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls(payload).getFeaturedUsersDetails;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getFeaturedPublishedTrip = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrls(payload).getFeaturedUserPublishedTrips;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getSearchedItinerary = (payload: any) => {
  try {
    const url: string =
      BASE_URL + getApiUrls(payload?.queryParams).getSearchedItinerary;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getSearchedDestinations = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getSearchedDestinations;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getPopularTrips = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getPopularTrips;
    const apiUrl: string = constructUrl(url, payload);
    return axios.get(apiUrl).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getWeekendGateways = () => {
  try {
    const url: string = BASE_URL + getApiUrls().getWeekendGateways;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getLongTrips = () => {
  try {
    const url: string = BASE_URL + getApiUrls().getLongTrips;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getSortedItinerary = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getSortedItinerary;
    const apiUrl: string = constructUrl(url, payload);
    return axios.get(apiUrl).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getPlaceDetails = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getPlaceDetails;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const getCoordinates = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().getCoordinates;
    return axios.post(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

const searchUsers = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls(payload?.queryParams).searchUsers;
    return axios.get(url).catch((error) => error);
  } catch (error) {
    return error;
  }
};

//get user details
const getUserDetails = () => {
  const token = localStorage.getItem("Login_Token") || "";
  const tokenWithoutQuotes = token.replace(/"/g, "");
  try {
    const url: string = BASE_URL + getApiUrls().getUserDetails;
    return axiosInstance
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenWithoutQuotes}`,
        },
      })
      .catch((error) => error);
  } catch (error) {
    return error;
  }
};


const updateUserDetails = (payload: any) => {
  try {
    const url: string = BASE_URL + getApiUrls().updateUserDetails;
    return axiosInstance.put(url, payload).catch((error) => error);
  } catch (error) {
    return error;
  }
};

export default {
  getPublishedTrip,
  getImages,
  getTrendingDestinationsList,
  getFeaturedUsersList,
  getItineraryDetails,
  getFeaturedUserDetails,
  getFeaturedPublishedTrip,
  getSearchedItinerary,
  getSearchedDestinations,
  getPopularTrips,
  getWeekendGateways,
  getLongTrips,
  getSortedItinerary,
  getPlaceDetails,
  getCoordinates,
  searchUsers,
  getUserDetails,
  updateUserDetails,
};
