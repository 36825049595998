import {
  CafeIcon,
  FlightIcon,
  LodgingIcon,
  SleepIcon,
  TrainIcon,
} from "../assests/exportImages";

export enum AuthKeys {
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
  REGISTER_WITH_EMAIL = "REGISTER_WITH_EMAIL",
  TELL_US_MORE = "TELL_US_MORE",
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
}

export enum typographyOptions {
  H1 = "h1",
  H2 = "h2",
  BODY1 = "body1",
  EXTRA_LARGE = "extraLarge",
  CARDS_HEADING = "cardsHeading",
  CARDS_DESCRIPTION = "cardsDescription",
  SUB_HEADING = "subHeading",
  HEADING = "heading",
  BACKGROUNDTEXT = "backgroundText",
  AUTH_HEADING = "authHeading",
  ERROR = "error",
}

export const PlacesTypeIcons = {
  ATTRACTION: LodgingIcon,
  HOTEL: SleepIcon,
  CAFE: CafeIcon,
  SHOOPINGMALL: LodgingIcon,
  DINING: CafeIcon,
  FLIGHT: FlightIcon,
  TRAIN: TrainIcon,
};

export const PlacesType = {
  PLACE: "Attraction",
  CAFE: "Cafe",
  SHOOPINGMALL: "Shopping Mall",
  ATTRACTION: "Attraction",
  TRAIN: "Train",
  FLIGHT: "Flight",
  HOTEL: "Hotel",
};

// travel guide types of trip dropdown
export const typesOfTrip = [
  { name: "dayTrips", displayName: "Day trips" },
  { name: "weekendGetaways", displayName: "Weekend Getaways" },
  { name: "shortTrips", displayName: "Short trips" },
  { name: "weekLongTrips", displayName: "WeekLong  trips" },
  { name: "longTrip", displayName: "Long trips" },
];

// tarvel guide dropdown types
export const sortByTypes = ["Recent", "Popular"];

export enum SearchTypes {
  Destination = "Destination",
  Itinerary = "Itinerary",
  Users = "Itinerary",
}

export enum DestinationDetailTypes {
  // HOTEL = "Hotel",
  // CAFE = "Cafe",
  // SHOOPINGMALL = "Shopping Mall",
  // Attraction: "Attraction",
  City = "City",
  country = "country",
  State = "State",
}

// Rating categories and labels
export const ratingCategories = [
  { label: "Excellent", rating: 5 },
  { label: "Very Good", rating: 4 },
  { label: "Average", rating: 3 },
  { label: "Poor", rating: 2 },
  { label: "Terrible", rating: 1 },
];
