import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import CustomSelect from "../../components/atom/Select";
import { Card } from "../../components/molecule/Card";
import { PlanYourNextTrip } from "../../components/template/PlanYourNextTrip";
import { sortByTypes, typesOfTrip, typographyOptions } from "../../constants";
import { getStoredImages } from "../../helper";
import en from "../../locale/en.json";
import { colors } from "../../themes/colors";
import styles from "./components.module.css";
import { ArrowBack, ArrowNext } from "../../assests/exportImages";

interface ISearchPageContent {
  searchInput: string;
  handleCardClick: Function | any;
  setSelectedOption: Function | any;
  selectedOption: object | any;
  setSortBy: Function | any;
  sortBy: string;
}

const CARDS_PER_PAGE = 18;

export const SearchPageContent = ({
  searchInput,
  handleCardClick,
  setSelectedOption,
  selectedOption,
  setSortBy,
  sortBy,
}: ISearchPageContent) => {
  const { searchItenairyList, searchItenairyLoader, loadingAllImages } =
    useSelector((state: any) => state.screenReducer);

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(searchItenairyList?.length / CARDS_PER_PAGE);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {searchItenairyLoader ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "200px",
          }}
        >
          <CircularProgress
            variant="indeterminate"
            sx={{
              color: colors.ThemeColor,
            }}
            size={40}
            thickness={4}
          />
        </Box>
      ) : (
        <>
          <Box className={styles.searchDetailPageContent}>
            <Container>
              <Box className={styles.searchPageContentIn}>
                <Stack gap="6px">
                  <Typography variant={typographyOptions.CARDS_HEADING}>
                    ‘{searchInput}’ Itinerary{" "}
                  </Typography>
                  <Typography
                    variant={typographyOptions.HEADING}
                    color={colors.darkGray}
                  >
                    {searchItenairyList?.length} results found
                  </Typography>
                </Stack>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "20px",
                  }}
                >
                  <CustomSelect
                    labelId="sort-by-label"
                    id="sort-by-select"
                    value={sortBy}
                    handleChange={(e: any) => {
                      setSortBy(e);
                    }}
                    options={sortByTypes}
                    placeholder="Select Option"
                  />

                  <CustomSelect
                    labelId="trip-select-label"
                    id="trip-select"
                    value={selectedOption?.displayName}
                    handleChange={(e: any) => {
                      setSelectedOption(e);
                    }}
                    adornmentText="Sort by"
                    options={typesOfTrip}
                    placeholder="Select Trip"
                    sx={{ width: { xs: "100%", sm: "250px" } }}
                    defaultKey={"displayName"}
                  />
                </Box>
              </Box>

              {searchItenairyList?.length ? (
                <>
                  <Box className={styles.searchPageContentBox}>
                    {searchItenairyList?.map((data: any, index: number) => {
                      
                      return (
                        <Card
                          key={index}
                          data={data}
                          style={{
                            width: "360px",
                            boxShadow: "0px 2px 18px 3px #00000005",
                          }}
                          height="auto"
                          image={getStoredImages(data?.reference)}
                          onClick={() => handleCardClick(data)}
                          loadingAllImages={loadingAllImages}
                        />
                      );
                    })}
                  </Box>

                  {/* pagination button */}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "32px",
                    }}
                  >
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      <img src={ArrowBack} alt="Arrow back" />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        style={{
                          margin: "0 5px",
                          padding: "0px 10px",
                          cursor: "pointer",
                          backgroundColor:
                            currentPage === index + 1 ? "#20B08F" : "white",
                          color: currentPage === index + 1 ? "#fff" : "#000",
                          border: "1px solid #F1F1F1",
                          borderRadius: "8px",
                        }}
                      >
                        {index + 1}
                      </button>
                    ))}
                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      <img src={ArrowNext} alt="Arrow Next" />
                    </button>
                  </Box>
                </>
              ) : (
                <Typography
                  textAlign={"center"}
                  color={colors.ThemeColor}
                  fontSize="20px"
                  variant={typographyOptions.HEADING}
                >
                  {en.noItineraryFound}
                </Typography>
              )}

              <PlanYourNextTrip
                style={{
                  flexDirection: "row-reverse",
                  backgroundColor: "rgba(224, 239, 235, 0.3)",
                  borderRadius: "10px",
                }}
              />
            </Container>
          </Box>
        </>
      )}
    </>
  );
};
