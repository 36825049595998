export enum authRoutes {
  LOGIN = "/login",
  REGISTER = "/register",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  REGISTER_WITH_EMAIL = "/register-email",
  TELL_US_MORE = "/tell-us-more",
  EMAIL_VERIFICATION = "/email-verification",
}

export enum screenRoutes {
  HOME = "/",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_OF_USE = "/terms-of-use",
  GET_APP = "/get-app",
  TRAVEL_GUIDES = "/travel-guides",
  SEARCH = "/search",
  SEARCH_DETAIL = "/search-detail",
  ITINARY_DETAIL = "/itinerary-detail/:id",
  TRAVELLER_DETAIL = "/traveller-detail/:id",
  DESTINATION_DETAIL = "/destination-detail/:id/:placeID",
  ALL_IMAGES_PREVIEW = "/destinationDetail/gallery/:id",
  ERROR_PAGE = "/error",
}
