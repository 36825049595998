import { screenActions } from "../actionTypes";

//get published trips
export const getPublishedTrip = () => {
  return {
    type: screenActions.GET_PUBLISHED_TRIP_REQUESTED,
  };
};

//get images
export const getImages = () => {
  return {
    type: screenActions.GET_IMAGES_REQUESTED,
  };
};

//get trending destinations list
export const getTrendingDestinations = () => {
  return {
    type: screenActions.GET_TRENDING_DESTINATIONS_REQUESTED,
  };
};

// get featured details
export const getFeaturedUsers = () => ({
  type: screenActions.GET_FEATURED_USERS_REQUESTED,
});

//get itinerary details
export const getItineraryDetails = (payload: any) => ({
  type: screenActions.GET_ITINERARY_DETAILS_REQUESTED,
  payload,
});

//get featured users details
export const getFeaturedUserDetails = (payload: any) => ({
  type: screenActions.GET_FEATURED_USER_DETAILS_REQUESTED,
  payload,
});

// get featured users published trips
export const getFeaturedUserPublishedTrips = (payload: any) => ({
  type: screenActions.GET_FEATURED_USER_PUBLISHED_TRIPS_REQUESTED,
  payload,
});

// get search itinerary
export const getSearchItinerary = (payload: any) => ({
  type: screenActions.GET_SEARCHED_ITINERARY_REQUESTED,
  payload,
});

// get search destinations
export const getSearchDestinations = (payload: any) => ({
  type: screenActions.GET_SEARCHED_DESTINATIONS_REQUESTED,
  payload,
});

// get search user
export const getSearchUser = (payload: any) => ({
  type: screenActions.GET_SEARCHED_USERS_REQUESTED,
  payload,
});

// get popular trips
export const getPopularTrips = (payload: any) => ({
  type: screenActions.GET_POPULAR_TRIPS_REQUESTED,
  payload,
});

// get weekend gateways
export const getWeekendGateways = () => ({
  type: screenActions.GET_WEEKENDS_GATEWAY_REQUESTED,
});

// get long trips
export const getLongTrips = () => ({
  type: screenActions.GET_LONG_TRIPS_REQUESTED,
});

// get sorted itinerary
export const getSortedItinerary = (payload: any) => ({
  type: screenActions.GET_SORTED_ITINERARY_REQUESTED,
  payload,
});

// get place details
export const getPlaceDetails = (payload: any) => ({
  type: screenActions.GET_PLACE_DETAILS_REQUESTED,
  payload,
});

// get coordinates
export const getCoordinates = (payload: any) => ({
  type: screenActions.GET_COORDINATES_REQUESTED,
  payload,
});

// search users
export const searchUsers = (payload: any) => ({
  type: screenActions.SEARCH_USERS_REQUESTED,
  payload,
});

// search users
export const getUserDetails = () => ({
  type: screenActions.GET_USER_DETAILS_REQUESTED,
});

// update user details
export const updateUserDetails = (payload: any) => ({
  type: screenActions.UPDATE_USER_DETAILS_REQUESTED,
  payload,
});
