import { Box, Skeleton, Typography } from "@mui/material";
import { typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";

interface IAboutMeCard {
  className?: string;
  heading?: string;
  about: string;
  addSeperator?: boolean; // Optional prop to add a border-top for seperation. Default is false.
  loading?: boolean; // Optional for loading
}

const AboutMeCard = (props: IAboutMeCard) => {
  const { className, heading, about, loading = false } = props;
  return (
    <Box
      className={className ?? ""}
      sx={{
        backgroundColor: "#EAF0F4B2",
        borderRadius: "20px",
        flex: "1",
      }}
    >
      {loading ? (
        <Box padding="16px 20px 12px 20px">
          <Skeleton width={"50%"} />
        </Box>
      ) : (
        <Typography
          variant={typographyOptions.H2}
          color={colors.black}
          fontSize="18px"
          padding="16px 20px 12px 20px"
        >
          {heading ? heading : "About Me"}
        </Typography>
      )}
      {/* {addSeperator ? <Box style={{border-top: 1px solid #F1F1F1}}></Box> : null} */}
      {loading ? (
        <Box padding="12px 20px 20px 20px" borderTop="2px solid #F1F1F1">
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Box>
      ) : (
        <Typography
          color={colors.secondaryGrey}
          variant={typographyOptions.H1}
          fontWeight="500"
          padding="12px 20px 20px 20px"
          borderTop="2px solid #F1F1F1"
        >
          {about}
        </Typography>
      )}
    </Box>
  );
};

export default AboutMeCard;
