// theme.js
import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";

// Common font family
const commonFontFamily = '"Mulish", "Roboto", "Helvetica", "Arial", sans-serif';

// Create theme
const theme = createTheme({
  palette: {
    primary: {
      main: colors.ThemeColor,
      contrastText: colors.primaryWhite,
    },
    secondary: {
      main: "#dc004e",
    },
  },
  typography: {
    fontFamily: commonFontFamily,
    fontSize: 16,

    h1: {
      fontWeight: 700,
      fontSize: "0.875em",
      fontFamily: commonFontFamily,
    },
    h2: {
      fontSize: "1rem",
      fontWeight: 600,
      color: colors.primaryWhite,
      fontFamily: commonFontFamily,
      "@media (max-width:600px)": {
        fontSize: "0.875em",
      },
    },
    body1: {
      fontWeight: 400,
      fontFamily: commonFontFamily,
    },
    error: {
      fontFamily: commonFontFamily,
      color: colors.tertiaryRed,
      fontWeight: 700,
      fontSize: "12px",
    },
    heading: {
      fontFamily: commonFontFamily,
      color: colors.transparentWhite,
      fontWeight: 500,
    },
    subHeading: {
      fontFamily: commonFontFamily,
      fontSize: "12px",
      fontWeight: 500,
      color: colors.secondaryGrey,
    },
    extraLarge: {
      fontFamily: commonFontFamily,
      fontSize: "62px",
      fontWeight: 600,
      color: colors.black,
      textAlign: "left",
      lineHeight: "66px",
      "@media (max-width:600px)": {
        fontSize: "50px",
      },
    },
    cardsHeading: {
      fontFamily: commonFontFamily,
      color: colors.darkGray,
      fontWeight: 600,
      fontSize: "37px",
      "@media (max-width:600px)": {
        fontSize: "30px",
      },
    },
    cardsDescription: {
      fontFamily: commonFontFamily,
      fontWeight: 500,
      color: colors.secondaryGrey,
      wordWrap: "break-word",
      lineHeight: "26px",
      "@media (max-width:600px)": {
        display: "none",
      },
    },
    backgroundText: {
      fontFamily: commonFontFamily,
      fontSize: "130px",
      fontWeight: 900,
      lineHeight: "163.15px",
      textAlign: "center",
      opacity: "3%",
      position: "absolute",
      top: "60px",
      left: "50%",
      transform: " translate(-50%, -50%)",
      whiteSpace: "nowrap",
      "@media (max-width:768px)": {
        display: "none",
      },
    },
    authHeading: {
      fontFamily: commonFontFamily,
      fontWeight: 800,
      color: colors.black,
      fontSize: "22px",
      lineHeight: "30.1px",
      "@media (max-width:600px)": {
        textAlign: "center",
      },
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "20px",
          paddingRight: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "90px",
          "@media (min-width:600px)": {
            paddingLeft: "50px",
            paddingRight: "50px",
          },
        },
      },
    },
  },
});

export default theme;
