import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Cross, Logo, Mail } from "../../assests/exportImages";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TermsOfUse = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div id="terms-of-use-id" className="flex flex-col w-full h-full">
      <div
        className="Header flex flex-row h-[80px] lg:h-[100px] w-full px-5 py-4 lg:px-20 items-center justify-between bg-[#ffffff]"
        style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06)" }}
      >
        <a href="/" className="w-[45%] cursor-pointer">
          <Logo />
        </a>
        <button
          className="bg-[#20B08F] hover:bg-[#1b967a] text-white text-base font-bold py-[8px] sm:py-[10px] px-[20px] sm:px-[40px] rounded-[8px] sm:rounded-[10px] font-Mulish transition duration-500 ease-in-out hover:scale-105"
          onClick={handleClickOpen}
        >
          Subscribe
        </button>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="relative !pb-[6px]">
            <p className=" text-2xl text-center lg:text-left font-semibold font-Mulish mt-3">
              {"Subscribe"}
            </p>
            <Cross onClick={() => setOpen(false)} />
          </DialogTitle>
          <DialogContent>
            <p
              className="text-sm lg:text-base text-center lg:text-left font-normal font-Mulish mb-8"
              id="alert-dialog-slide-description"
            >
              Let's get connected for more enhanced experience
            </p>
            {/* <input className='flex text-base font-Mulish py-[12px] w-full outline-none bg-[#f6f6f6] px-2 border-default rounded-[8px]' placeholder='Enter your email address' /> */}
            <TextField
              placeholder="Enter your email address"
              id="outlined-start-adornment"
              variant="outlined"
              className="flex text-base font-Mulish py-[12px] w-full outline-none bg-[#f6f6f6] px-2 border-0 rounded-[8px]"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <button className="bg-[#20B08F] hover:bg-[#1b967a] text-white text-base font-bold py-[10px] sm:py-[10px] px-[20px] sm:px-[40px] rounded-[8px] sm:rounded-[10px] font-Mulish mb-3">
              Subscribe
            </button>
          </DialogActions>
        </Dialog>
      </div>
      <div className="Fold1 flex flex-col h-full lg:max-w-[1090px] px-5 lg:px-20 items-center mt-12 lg:mt-20 m-0 lg:m-auto">
        <h1 className="text-[32px] md:text-[46px] leading-snug md:leading-[57px] text-center lg:text-right font-bold font-Mulish text-[#333333]">
          Terms of Use
        </h1>
        <p className="text-[16px] md:text-[18px] leading-[27px] text-center lg:text-right font-normal font-Mulish">
          Last updated: 2023-01-01
        </p>
        <div className="flex flex-col justify-center align-top pt-[53px] pb-[60px] max-w-[930px] mx-auto">
          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Introduction
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            Welcome to Escape Planner Pvt. Ltd (“Company”, “we”, “our”, “us”)!
            These Terms of Use (“Terms”, “Terms of Service”) govern your use of
            our website located at{" "}
            <a
              href="https://escapekar.com/"
              className="text-[#59B1E6] underline"
            >
              Escapekar.com
            </a>{" "}
            (together or individually “Service”) operated by Escape Planner Pvt.
            Ltd.Our Privacy Policy also governs your use of our Service and
            explains how we collect, safeguard and disclose information that
            results from your use of our web pages. Your agreement with us
            includes these Terms and our Privacy Policy (“Agreements”). You
            acknowledge that you have read and understood Agreements, and agree
            to be bound of them. If you do not agree with (or cannot comply
            with) Agreements, then you may not use the Service, but please let
            us know by emailing at{" "}
            <a
              href="mailto:admin@escapekar.com"
              className="text-[#59B1E6] underline"
            >
              admin@escapekar.com
            </a>{" "}
            so we can try to find a solution. These Terms apply to all visitors,
            users and others who wish to access or use Service
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Communications
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            By using our Service, you agree to subscribe to newsletters,
            marketing or promotional materials and other information we may
            send. However, you may opt out of receiving any, or all, of these
            communications from us by following the unsubscribe link or by
            emailing at{" "}
            <a
              href="mailto:admin@escapekar.com"
              className="text-[#59B1E6] underline"
            >
              admin@escapekar.com
            </a>
            .
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Content
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            Our Service allows you to post, link, store, share and otherwise
            make available certain information, text, graphics, videos, or other
            material (“Content”). You are responsible for Content that you post
            on or through Service, including its legality, reliability, and
            appropriateness.
            <br />
            By posting Content on or through Service, You represent and warrant
            that: (i) Content is yours (you own it) and/or you have the right to
            use it and the right to grant us the rights and license as provided
            in these Terms, and (ii) that the posting of your Content on or
            through Service does not violate the privacy rights, publicity
            rights, copyrights, contract rights or any other rights of any
            person or entity. We reserve the right to terminate the account of
            anyone found to be infringing on a copyright.
            <br />
            You retain any and all of your rights to any Content you submit,
            post or display on or through Service and you are responsible for
            protecting those rights. We take no responsibility and assume no
            liability for Content you or any third party posts on or through
            Service. However, by posting Content using Service you grant us the
            right and license to use, modify, publicly perform, publicly
            display, reproduce, and distribute such Content on and through
            Service. You agree that this license includes the right for us to
            make your Content available to other users of Service, who may also
            use your Content subject to these Terms.
            <br />
            Escape Planner Pvt. Ltd has the right but not the obligation to
            monitor and edit all Content provided by users.
            <br />
            In addition, Content found on or through this Service are the
            property of Escape Planner Pvt. Ltd or used with permission. You may
            not distribute, modify, transmit, reuse, download, repost, copy, or
            use said Content, whether in whole or in part, for commercial
            purposes or for personal gain, without express advance written
            permission from us.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Objectionable Content
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            You are not allowed to post content that is offensive, insensitive,
            upsetting, intended to disgust, in exceptionally poor taste, or just
            plain creepy. Examples of such content include:
            <br />
            Defamatory, discriminatory, or mean-spirited content, including
            references or commentary about religion, race, sexual orientation,
            gender, national/ethnic origin, or other targeted groups,
            particularly if the content is likely to humiliate, intimidate, or
            harm a targeted individual or group.
            <br />
            Realistic portrayals of people or animals being killed, maimed,
            tortured, or abused, or content that encourages violence.
            <br />
            Depictions that encourage illegal or reckless use of weapons and
            dangerous objects, or facilitate the purchase of firearms or
            ammunition.
            <br />
            Overtly sexual or pornographic material, defined by Webster’s
            Dictionary as “explicit descriptions or displays of sexual organs or
            activities intended to stimulate erotic rather than aesthetic or
            emotional feelings.”
            <br />
            Inflammatory religious commentary or inaccurate or misleading
            quotations of religious texts.
            <br />
            Intentionally false and misleading information, including inaccurate
            place data or false content that resemble jokes.
            <br />
            If you discover objectionable content, you may use the in-app
            reporting functionality to alert us to it. If we deem the content to
            be objectionable, we will usually take action within 24 hours.
            <br />
            Wanderlog will promptly remove objectionable content and expel users
            who post objectionable content.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Prohibited Uses
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            You may use Service only for lawful purposes and in accordance with
            Terms. You agree not to use Service:
            <br />
            0.1. In any way that violates any applicable national or
            international law or regulation.
            <br />
            0.2. For the purpose of exploiting, harming, or attempting to
            exploit or harm minors in any way by exposing them to inappropriate
            content or otherwise.
            <br />
            0.3. To transmit, or procure the sending of, any advertising or
            promotional material, including any “junk mail”, “chain letter,”
            “spam,” or any other similar solicitation.
            <br />
            0.4. To impersonate or attempt to impersonate Company, a Company
            employee, another user, or any other person or entity.
            <br />
            0.5. In any way that infringes upon the rights of others, or in any
            way is illegal, threatening, fraudulent, or harmful, or in
            connection with any unlawful, illegal, fraudulent, or harmful
            purpose or activity.
            <br />
            0.6. To engage in any other conduct that restricts or inhibits
            anyone’s use or enjoyment of Service, or which, as determined by us,
            may harm or offend Company or users of Service or expose them to
            liability.
          </p>

          <h4 className="font-Mulish text-[20px] leading-[25px] font-semibold self-start mt-[24px] mb-[8px]">
            Additionally, you agree not to:
          </h4>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            0.1. Use Service in any manner that could disable, overburden,
            damage, or impair Service or interfere with any other party’s use of
            Service, including their ability to engage in real time activities
            through Service.
            <br />
            0.2. Use any robot, spider, or other automatic device, process, or
            means to access Service for any purpose, including monitoring or
            copying any of the material on Service.
            <br />
            0.3. Use any manual process to monitor or copy any of the material
            on Service or for any other unauthorized purpose without our prior
            written consent.
            <br />
            0.4. Use any device, software, or routine that interferes with the
            proper working of Service.
            <br />
            0.5. Introduce any viruses, trojan horses, worms, logic bombs, or
            other material which is malicious or technologically harmful.
            <br />
            0.6. Attempt to gain unauthorized access to, interfere with, damage,
            or disrupt any parts of Service, the server on which Service is
            stored, or any server, computer, or database connected to Service.
            <br />
            0.7. Attack Service via a denial-of-service attack or a distributed
            denial-of-service attack.
            <br />
            0.8. Take any action that may damage or falsify Company rating.
            <br />
            0.9. Otherwise attempt to interfere with the proper working of
            Service.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Analytics
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            No Use By Minors
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            Service is intended only for access and use by individuals at least
            eighteen (18) years old. By accessing or using Service, you warrant
            and represent that you are at least eighteen (18) years of age and
            with the full authority, right, and capacity to enter into this
            agreement and abide by all of the terms and conditions of Terms. If
            you are not at least eighteen (18) years old, you are prohibited
            from both the access and usage of Service.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Accounts
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            When you create an account with us, you guarantee that you are above
            the age of 18, and that the information you provide us is accurate,
            complete, and current at all times. Inaccurate, incomplete, or
            obsolete information may result in the immediate termination of your
            account on Service.
            <br />
            You are responsible for maintaining the confidentiality of your
            account and password, including but not limited to the restriction
            of access to your computer and/or account. You agree to accept
            responsibility for any and all activities or actions that occur
            under your account and/or password, whether your password is with
            our Service or a third-party service. You must notify us immediately
            upon becoming aware of any breach of security or unauthorized use of
            your account.
            <br />
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than you,
            without appropriate authorization. You may not use as a username any
            name that is offensive, vulgar or obscene.
            <br />
            We reserve the right to refuse service, terminate accounts, remove
            or edit content, or cancel orders in our sole discretion.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Intellectual Property
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            Service and its original content (excluding Content provided by
            users), features and functionality are and will remain the exclusive
            property of Escape Planner Pvt. Ltd and its licensors. Service is
            protected by copyright, trademark, and other laws of and foreign
            countries. Our trademarks may not be used in connection with any
            product or service without the prior written consent of Escape
            Planner Pvt. Ltd.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Error Reporting and Feedback
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            You may provide us either directly at admin@escapekar.com or via
            third party sites and tools with information and feedback concerning
            errors, suggestions for improvements, ideas, problems, complaints,
            and other matters related to our Service (“Feedback”). You
            acknowledge and agree that:
            <br />
            (i) you shall not retain, acquire or assert any intellectual
            property right or other right, title or interest in or to the
            Feedback;
            <br />
            (ii) Company may have development ideas similar to the Feedback;
            <br />
            (iii) Feedback does not contain confidential information or
            proprietary information from you or any third party; and
            <br />
            (iv) Company is not under any obligation of confidentiality with
            respect to the Feedback. In the event the transfer of the ownership
            to the Feedback is not possible due to applicable mandatory laws,
            you grant Company and its affiliates an exclusive, transferable,
            irrevocable, free-of-charge, sub-licensable, unlimited and perpetual
            right to use (including copy, modify, create derivative works,
            publish, distribute and commercialize) Feedback in any manner and
            for any purpose.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Links To Other Web Sites
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            Our Service may contain links to third party web sites or services
            that are not owned or controlled by Escape Planner Pvt. Ltd.Escape
            Planner Pvt. Ltd has no control over, and assumes no responsibility
            for the content, privacy policies, or practices of any third party
            web sites or services. We do not warrant the offerings of any of
            these entities/individuals or their websites. YOU ACKNOWLEDGE AND
            AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR
            INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY
            OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS
            OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES
            OR SERVICES.WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
            PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU
            VISIT.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Disclaimer Of Warranty
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF
            ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES,
            OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND
            ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.NEITHER
            COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR
            REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
            RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES.
            WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE
            ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES,
            THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
            THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT
            MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
            OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.COMPANY
            HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
            WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
            PARTICULAR PURPOSE.THE FOREGOING DOES NOT AFFECT ANY WARRANTIES
            WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Limitation Of Liability
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
            DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
            PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT
            ARISES (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES
            OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY,
            WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN
            AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR
            ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
            WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE,
            ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL,
            STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF
            COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON
            THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE
            PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
            CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
            DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Termination
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            We may terminate or suspend your account and bar access to Service
            immediately, without prior notice or liability, under our sole
            discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of Terms.
            <br />
            If you wish to terminate your account, you may simply discontinue
            using Service.
            <br />
            All provisions of Terms which by their nature should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Governing Law
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            These Terms shall be governed and construed in accordance with the
            laws of India, which governing law applies to agreement without
            regard to its conflict of law provisions.
            <br />
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service and supersede and replace any prior agreements we might have
            had between us regarding Service.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Changes To Service
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            We reserve the right to withdraw or amend our Service, and any
            service or material we provide via Service, in our sole discretion
            without notice. We will not be liable if for any reason all or any
            part of Service is unavailable at any time or for any period. From
            time to time, we may restrict access to some parts of Service, or
            the entire Service, to users, including registered users.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Amendments To Terms
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            We may amend Terms at any time by posting the amended terms on this
            site. It is your responsibility to review these Terms periodically.
            <br />
            Your continued use of the Platform following the posting of revised
            Terms means that you accept and agree to the changes. You are
            expected to check this page frequently so you are aware of any
            changes, as they are binding on you.
            <br />
            By continuing to access or use our Service after any revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, you are no longer authorized to use
            Service.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Waiver And Severability
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            No waiver by Company of any term or condition set forth in Terms
            shall be deemed a further or continuing waiver of such term or
            condition or a waiver of any other term or condition, and any
            failure of Company to assert a right or provision under Terms shall
            not constitute a waiver of such right or provision.
            <br />
            If any provision of Terms is held by a court or other tribunal of
            competent jurisdiction to be invalid, illegal or unenforceable for
            any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of Terms will
            continue in full force and effect.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Acknowledgement
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
            THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
            THEM.
          </p>

          <h3 className="font-Mulish text-[24px] leading-[23px] font-semibold self-start mt-[24px] mb-[8px]">
            Contact Us
          </h3>
          <p className="font-Mulish text-[16px] leading-[27px] font-normal tracking-[0.323px] text-[#323232]">
            Please send your feedback, comments, requests for technical support
            by email:{" "}
            <a
              href="mailto:admin@escapekar.com"
              className="text-[#59B1E6] underline"
            >
              admin@escapekar.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
