import { useState } from "react";
import { SmallQR, TravelGraphic } from "../../assests/exportImages";
import {
  AndriodDownload,
  AppleDownload,
} from "../../components/atom/DownloadAppIcons";
import { QRModal } from "../../components/template/QRModal";
import { Container } from "@mui/material";

export const Explore = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div
      style={{
        background:
          "linear-gradient(180deg, #E2FFF8 14.25%, rgba(255, 255, 255, 0) 100%)",
      }}
    >
      {/* <Header boxShadow={"none"} /> */}

      <Container>
        <div className="MainTag  flex flex-col lg:flex-row items-center pb-[30px] lg:pb-[67px] mt-10">
          <div className="HomeText flex flex-col items-center lg:items-start w-[100%] lg:w-[50%] mt-12 lg:mt-[0]">
            <h1 className="text-[26px] leading-[36px] md:leading-[70px] md:text-[56px] font-bold max-w-[581px] lg:max-w-none mb-5 font-Mulish text-center md:text-left">
              Let’s explore, create and manage trip{" "}
              <span className="lg:block text-red-500">for free</span>
            </h1>
            <p className=" text-base md:text-2xl lg:text-base font-normal max-w-[533px] mb-10 font-Mulish text-[#00000080] text-center md:text-left">
              For many people organising trip is a headache, we made it easy for
              you plus we won’t charge you for that
            </p>
            <div className="flex flex-row items-center gap-[10px]">
              <AndriodDownload />
              <AppleDownload />
              <div className="hidden lg:flex">
                <img
                  src={SmallQR}
                  alt="QR"
                  onClick={handleOpen}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>

          <QRModal open={open} handleClose={handleClose} />

          <div className="flex flex-col items-center lg:items-end lg:justify-center w-[100%] lg:w-[50%] mt-14 lg:mt-4">
            <TravelGraphic />
          </div>
        </div>
      </Container>
    </div>
  );
};
