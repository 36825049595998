import { Box, Skeleton, Stack, Typography } from "@mui/material";
import styles from "../components.module.css";
import { typographyOptions } from "../../constants";
import { colors } from "../../themes/colors";

const FollowersUI = ({ followers, trips, loading }: any) => {
  return (
    <Box className={styles.followersUIBox}>
      <Stack gap="2px" alignItems="center">
        {loading ? (
          <Skeleton animation="wave" width="50px" height="20px" />
        ) : (
          <Typography
            variant={typographyOptions.H1}
            fontSize="20px"
            color={colors.transparentWhite}
          >
            {trips}
          </Typography>
        )}
        {loading ? (
          <Skeleton animation="wave" width="50px" height="20px" />
        ) : (
          <Typography
            variant={typographyOptions.H1}
            fontSize="14px"
            color={colors.quatenaryGrey}
          >
            Trips
          </Typography>
        )}
      </Stack>

      {/* Separator */}
      <Box
        style={{
          border: "1px solid #EAEAEA",
          backgroundColor: colors.quatenaryGrey,
          height: "100%",
        }}
      ></Box>

      <Stack gap="2px" alignItems={"center"}>
        {loading ? (
          <Skeleton animation="wave" width="50px" height="20px" />
        ) : (
          <Typography
            variant={typographyOptions.H1}
            fontSize="20px"
            color={colors.transparentWhite}
          >
            {followers ?? 0}
          </Typography>
        )}
        {loading ? (
          <Skeleton animation="wave" width="50px" height="20px" />
        ) : (
          <Typography
            variant={typographyOptions.H1}
            fontSize="14px"
            color={colors.quatenaryGrey}
          >
            Followers
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export default FollowersUI;
