import { Box, Skeleton, Stack } from "@mui/material";
import styles from "../components.module.css";

export const SkeletonCard = () => {
  return (
    <Box className={styles.card}>
      <Stack spacing={1}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ borderRadius: "8px", height: "180px" }}
        />
        <Skeleton animation="wave" height={30} width="80%" />
        <Skeleton animation="wave" height={20} width="70%" />
        <Skeleton animation="wave" height={20} width="50%" />
      </Stack>
    </Box>
  );
};
